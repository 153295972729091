import { ArrowBack, Chat, Note, StickyNote2 } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AppDrawer from "../../Layout/AppDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  findDuration,
  FormatArray,
  removeLastCommaAndWord,
  splitDateTime,
  SubFormatArray,
  ChildOfSubFormatArray,
  GrandChildOfSubFormatArray,
} from "../../Utils/utils";
import { getData } from "../../API/apiService";
import { useAuth } from "../../customHooks/AuthContext";
import CachedIcon from "@mui/icons-material/Cached";
import SessionFeedback from "./SessionFeedback";
import {
  sessionAutoRefreshTiming,
  sessionReportMaxAttemptCount,
} from "../../Utils/constant";
import React from "react";

import {
  TreatmentPlanPharmacologicalTable,
  TreatmentPlanNonPharmacologicalTable,
} from "./TreatmentPlanPharmacologicalTable";

interface DataItem {
  [key: string]: string[];
}

type SummaryEntry = [string, Record<string, any>];

interface SessionDignosisType {
  disorder: string;
  code: string;
  severity: string;
}

export const sessionReportTitle = {
  display: "block",
  fontWeight: "bold",
  marginLeft: "1pc",
  backgroundColor: "#fff6d1",
  width: "100%",
  padding: "0.5em",
};

export const sessionReportSubtitle = {
  display: "block",
  fontWeight: "bold",
  marginLeft: "1pc",
  backgroundColor: "#ffe478",
  width: "fit-content",
  borderRadius: "4px",
  padding: "0.3em",
};

// const staticSummaryAPIResponseFormat = 
//   [
//     [
//       "GAD",
//       "Yes"
//     ],
//     [
//       "MDD",
//       "Yes"
//     ],
//   [
//     "summary_gad",
//     {
//       "1_gad_symptom": [
//         "No",
//         "The patient does not mention experiencing excessive anxiety and worry for at least 6 months. The conversation is focused on symptoms of depression rather than generalized anxiety disorder.",
//       ],
//       "2_gad_physical_symptoms": [
//         "Yes",
//         "The patient reports experiencing multiple physical symptoms like fatigue ('I just feel so exhausted'), difficulty concentrating ('It's like the television. I used to like watching the soaps...and now 10 minutes later I just, you know, I'm thinking of something else'), and sleep disturbance ('it takes me ages to go to sleep', 'I wake up about last night, I think was about four o'clock')",
//       ],
//       "3_gad_control_of_worry": [
//         "No",
//         "There is no mention of the patient having difficulty controlling worry specifically. The conversation is focused on symptoms of low mood and depression.",
//       ],
//       "4_gad_impairment": [
//         "Yes",
//         "The symptoms appear to be causing significant impairment in social and occupational functioning, with the patient struggling to care for her children ('The kids are they come from school, get her own tea. Right. I should be doing more for them. Really? But no, I'm just a bit useless as that at the moment'), not going to work ('I'm not, I've not been going in as much because I just feel so exhausted'), and having difficulties in her relationship ('But he's not ringing as much and he used to text but he, he's got, you know, he's getting with me not wanting to grow and').",
//       ],
//       "5_gad_exclusion": [
//         "No",
//         "There is no indication that the symptoms are better explained by panic disorder, social anxiety disorder, obsessive-compulsive disorder, a medical condition, medications, or substance use.",
//       ],
//       "5a_gad_panic_exclusion": [
//         "No",
//         "There is no mention of the patient experiencing recurrent unexpected panic attacks.",
//       ],
//       "5b_gad_social_anxiety_exclusion": [
//         "No",
//         "There is no indication that the patient's anxiety primarily focuses on social situations where they fear being negatively evaluated.",
//       ],
//       "5c_gad_ocd_exclusion": [
//         "No",
//         "There is no mention of the patient experiencing persistent, unwanted, and intrusive thoughts or repetitive behaviors characteristic of obsessive-compulsive disorder.",
//       ],
//       "5d_gad_medical_exclusion": [
//         "No",
//         "There is no indication that the symptoms are attributable to substance use, medication, or another medical condition.",
//       ],
//       "6a_gad_severity_mild": [
//         "No",
//         "The severity does not appear to be mild given the significant impairment in social and occupational functioning.",
//       ],
//       "6b_gad_severity_moderate": [
//         "No",
//         "The severity of the symptoms does not seem to be moderate, as the patient is struggling significantly in multiple areas of functioning.",
//       ],
//       "6c_gad_severity_severe": [
//         "Yes",
//         "The severity of the symptoms appears to be severe, as the patient is experiencing significant impairment in social, occupational, and personal functioning, and has had thoughts of not wanting to wake up, suggesting severe depression.",
//       ],
//     },
//   ],
//   [
//     "summary_general",
//     {
//       medium: [
//         "In-person visit",
//         "The transcript suggests an in-person conversation between the patient and the doctor.",
//       ],
//       age: [
//         "The patient had children later in life, suggesting they are older.",
//       ],
//       past_medical_history: [],
//       past_psychiatric_history: [
//         "The patient mentions a previous episode of depression and taking tablets (likely an overdose attempt) around 4 years ago when their husband left.",
//       ],
//       "past psychiatric_medication": [],
//       cognitive_history: [
//         "The patient mentions having problems with concentration and memory.",
//       ],
//       cognitive_functioning: [
//         "The patient's cognitive functioning does not seem to have been formally evaluated, but they report issues with concentration, memory, and feeling like their brain has not been switched off.",
//       ],
//       legal_problem: [],
//       liver_problem: [],
//       heart_problem: [],
//       vitamin_deficiency: [],
//       infection: [],
//       suicide_attempt: [
//         "The patient mentions taking tablets (likely an overdose attempt) around 4 years ago when their husband left.",
//       ],
//       suicide_ideation: [
//         "The patient mentions having thoughts of not wanting to wake up at night when they are alone and everything feels hard.",
//       ],
//       overdose: [
//         "The patient mentions taking tablets (likely an overdose attempt) around 4 years ago when their husband left.",
//       ],
//       violence: [],
//       child_trauma: [],
//       relationship: [
//         "The patient has a boyfriend named Dave, and their relationship seems to be strained due to the patient's low mood and lack of interest in intimacy.",
//         "The patient has children, including a 19-year-old child, and they feel they are not doing enough for their children.",
//         "The patient's sister has noticed their low mood and encouraged them to seek help.",
//       ],
//       gender_issue: [],
//       firearm: [],
//       finance: [
//         "The patient mentions having problems with bills, catalogs, and letters, suggesting financial difficulties.",
//         "The patient's wages have been cut due to a supermarket takeover.",
//       ],
//       friends: [
//         "The patient used to go out with friends for movies, but they can't be bothered anymore.",
//         "The patient has a couple of friends who are supportive.",
//       ],
//       safety: [
//         "The patient mentions feeling able to keep themselves safe from hurting themselves at the moment and knowing to come to the doctor if that changes.",
//       ],
//       education: [],
//       employment: [
//         "The patient used to work but has not been going in as much due to feeling exhausted.",
//         "The patient's wages have been cut due to a supermarket takeover.",
//       ],
//       mental_health: [
//         "The doctor suggests that the patient is suffering from symptoms that strongly indicate depression.",
//       ],
//       other_mental_health: [],
//       vocabulary: [
//         "The patient's vocabulary and choice of words do not suggest any particular personality traits or concerns.",
//       ],
//       framework: [],
//       diagnosis: [
//         "The doctor suggests that the patient is suffering from symptoms that strongly indicate depression.",
//       ],
//       treatment: [],
//       secondary_diagnosis: [],
//       reaction: [],
//       insurance: [],
//       pregnancy: [],
//       other: [],
//       "": [],
//       sleep_pattern: [
//         "The patient has difficulty falling asleep, taking a couple of hours to fall asleep.",
//         "The patient wakes up around 4 AM and cannot get back to sleep.",
//         "The patient feels exhausted in the morning, like their brain has not been switched off.",
//       ],
//       physical_activity: [
//         "The patient used to do a lot with their kids, like swimming and playing, but now they just spend the day on the sofa.",
//       ],
//       daily_routine: [
//         "The patient's daily routine seems unstructured, with them spending most of the day on the sofa.",
//       ],
//       diet_and_nutrition: [
//         "The patient's appetite has gone down in the last couple of months.",
//         "The patient does not bother to eat when their kids come home from school and make their own food.",
//       ],
//       stress_level: [
//         "The patient mentions feeling overwhelmed by things piling up and not coping with things.",
//       ],
//       trigger: [],
//       energy_level: ["The patient has low energy levels and feels exhausted."],
//       hobbies_and_interests: [
//         "The patient used to enjoy going out with friends for movies, but they can't be bothered anymore.",
//         "The patient used to enjoy watching soap operas on TV, but now they lose interest after 10 minutes.",
//       ],
//       self_perception_and_confidence: [
//         "The patient mentions feeling useless as a mom and not taking pride in their appearance like they used to.",
//       ],
//       self_care: [
//         "The patient mentions not bothering with their hair and appearance anymore, suggesting a decline in self-care.",
//       ],
//       support_system: [
//         "The patient's sister has noticed their low mood and encouraged them to seek help.",
//         "The patient has a couple of supportive friends.",
//       ],
//       thyroid_problem: [],
//       adrenal_problem: [],
//       neurological_problem: [],
//       inflammatory_autoimmune_problem: [],
//       hormonal_imbalances: [],
//       gastrointestinal_problem: [],
//       diabetes_mellitus: [],
//       sex: [
//         "Female",
//         "The patient mentions having a boyfriend named Dave and refers to herself using feminine pronouns.",
//       ],
//       allergies: [],
//       patient_category: ["Mental"],
//       visit_scenario: ["Acute complaint"],
//       chief_complaint: [
//         "The patient's sister has noticed that the patient is feeling low and encouraged them to seek help.",
//       ],
//       history_of_present_illness: [
//         "The patient has been feeling really down, with no energy, problems with sleeping and eating, difficulty concentrating, not enjoying things, and struggling with taking care of their kids and relationship for the past few months.",
//       ],
//       family_history_medical: [],
//       review_of_systems: [],
//       medication_review: [],
//       physical_examination: [],
//       assessment: [],
//       plan: [],
//       interval_history: [],
//       lab_work: [],
//       recent_lab_work: [],
//       health_maintenance_review: [],
//       risk_factor_assessment: [],
//       growth_and_development: [],
//       immunization_status: [],
//       functional_status: [],
//     },
//   ],
//   [
//     "summary_induced",
//     {
//       "1_intoxication": [
//         "No",
//         "There is no mention of the patient recently using any substances within a timeframe consistent with acute intoxication.",
//       ],
//       "2_intoxication": [
//         "No",
//         "There is no evidence of significant maladaptive behavioral or psychological changes immediately after recent substance use.",
//       ],
//       "3_intoxication": [
//         "No",
//         "The patient does not exhibit at least two of the listed symptoms immediately after recent substance use.",
//       ],
//       "4_intoxication": [
//         "No",
//         "The symptoms are not attributable to another medical condition or mental disorder.",
//       ],
//       "1_induced_anxiety": [
//         "No",
//         "There is no mention of the patient experiencing panic attacks or anxiety.",
//       ],
//       "2_induced_anxiety": [
//         "No",
//         "Not applicable as the patient does not have panic attacks or anxiety.",
//       ],
//       "3_induced_anxiety": [
//         "No",
//         "The disturbance does not occur exclusively during the course of a delirium.",
//       ],
//       "4_induced_anxiety": [
//         "No",
//         "Symptoms are not better explained by an anxiety disorder that is not substance-induced.",
//       ],
//       "1_induced_bipolar": [
//         "No",
//         "There is no mention of the patient experiencing a persistent disturbance in mood characterized by elevated, expansive, or irritable mood.",
//       ],
//       "2_induced_bipolar": [
//         "No",
//         "Not applicable as the patient does not have a mood disturbance.",
//       ],
//       "3_induced_bipolar": [
//         "No",
//         "The disturbance does not occur exclusively during the course of a delirium.",
//       ],
//       "4_induced_bipolar": [
//         "No",
//         "Symptoms are not better explained by a bipolar disorder that is not substance-induced.",
//       ],
//       "1_induced_delirium": [
//         "No",
//         "There is no mention of the patient experiencing a disturbance in attention and awareness that developed over a short period and fluctuates during the day.",
//       ],
//       "2_induced_delirium": [
//         "No",
//         "There is no mention of the patient experiencing additional cognitive disturbances characteristic of delirium.",
//       ],
//       "3_induced_delirium": [
//         "No",
//         "There is no evidence that the disturbance is a direct physiological consequence of sustained substance use causing delirium.",
//       ],
//       "4_induced_delirium": [
//         "No",
//         "Symptoms do not occur in the context of a severely reduced level of arousal such as coma.",
//       ],
//       "1_induced_mdd": [
//         "Yes",
//         "The patient experiences a persistent disturbance in mood characterized by depressed mood and markedly diminished interest or pleasure in activities.",
//       ],
//       "2_induced_mdd": [
//         "No",
//         "There is no mention of the mood disturbance developing during, soon after, or as a result of sustained substance use, intoxication, or withdrawal.",
//       ],
//       "3_induced_mdd": [
//         "No",
//         "The disturbance does not occur exclusively during the course of a delirium.",
//       ],
//       "4_induced_mdd": [
//         "No",
//         "The symptoms are not better explained by a depressive disorder that is not substance-induced.",
//       ],
//       "0_induced_nc": [
//         "No",
//         "There is no evidence of modest cognitive decline from a previous level of performance in one or more cognitive domains.",
//       ],
//       "1_induced_nc": [
//         "No",
//         "There is no evidence of significant cognitive decline from a previous level of performance in one or more cognitive domains.",
//       ],
//       "1_1_induced_nc": [
//         "No",
//         "The patient does not exhibit amnestic-confabulatory symptoms, meaning they do not fabricate details due to loss of memory.",
//       ],
//       "2_induced_nc": [
//         "No",
//         "Not applicable as there is no cognitive decline.",
//       ],
//       "3_induced_nc": [
//         "No",
//         "The disturbance does not occur exclusively during the course of a delirium.",
//       ],
//       "4_induced_nc": [
//         "No",
//         "Symptoms are not better explained by a neurocognitive disorder that is not substance-induced.",
//       ],
//       "1_induced_ocd": [
//         "No",
//         "There is no mention of the patient having obsessions, compulsions, skin picking, hair pulling, other body-focused repetitive behaviors, or other symptoms characteristic of obsessive-compulsive and related disorders.",
//       ],
//       "2_induced_ocd": [
//         "No",
//         "Not applicable as the patient does not exhibit OCD symptoms.",
//       ],
//       "3_induced_ocd": [
//         "No",
//         "The disturbance does not occur exclusively during the course of a delirium.",
//       ],
//       "4_induced_ocd": [
//         "No",
//         "Symptoms are not better explained by an obsessive-compulsive disorder that is not substance-induced.",
//       ],
//       "1_induced_psychotic": [
//         "No",
//         "There is no mention of the patient having hallucinations or delusions that are in excess of those typically associated with substance use and are severe.",
//       ],
//       "2_induced_psychotic": [
//         "No",
//         "Not applicable as the patient does not have psychotic symptoms.",
//       ],
//       "3_induced_psychotic": ["No", "Not applicable."],
//       "4_induced_psychotic": [
//         "No",
//         "Symptoms are not better explained by a primary psychotic disorder.",
//       ],
//       "1_induced_sleep": [
//         "Yes",
//         "The patient has a prominent and severe disturbance in sleep, such as insomnia and difficulty falling asleep.",
//       ],
//       "2_induced_sleep": [
//         "No",
//         "There is no mention of the sleep disturbance developing during, soon after, or as a result of sustained substance use, intoxication, or withdrawal.",
//       ],
//       "3_induced_sleep": [
//         "No",
//         "The disturbance does not occur exclusively during the course of a delirium.",
//       ],
//       "4_induced_sleep": [
//         "No",
//         "The symptoms are not better explained by a sleep disorder that is not substance-induced.",
//       ],
//       "1_induced_sex": [
//         "Yes",
//         "The patient mentions that their partner is trying to pressure them for sex, but they are not interested, indicating a significant disturbance in sexual function.",
//       ],
//       "2_induced_sex": [
//         "No",
//         "There is no mention of the sexual disturbance developing during, soon after, or as a result of sustained substance use, intoxication, or withdrawal.",
//       ],
//       "3_induced_sex": [
//         "No",
//         "The disturbance does not occur exclusively during the course of a delirium.",
//       ],
//       "4_induced_sex": [
//         "No",
//         "The symptoms are not better explained by a sexual dysfunction that is not substance-induced.",
//       ],
//     },
//   ],
//   [
//     "summary_mdd",
//     {
//       "1_mdd_mood": [
//         "Yes",
//         "The patient mentions feeling 'very black' and 'miserable' in the mornings, indicating a depressed mood.",
//       ],
//       "2_mdd_interest": [
//         "Yes",
//         "The patient says they 'can't be bothered' with things they used to enjoy like going out with friends, suggesting a loss of interest or pleasure.",
//       ],
//       "3_mdd_weight": [
//         "Yes",
//         "The patient mentions their appetite has gone down and they have lost some weight, saying 'the last couple of months this is a bit looser'.",
//       ],
//       "4_mdd_insomnia": [
//         "Yes",
//         "The patient has trouble sleeping, taking a couple of hours to fall asleep and waking up around 4 am without being able to go back to sleep.",
//       ],
//       "5_mdd_retardation": [
//         "No",
//         "There is no clear mention of the patient feeling restless or slowed down in a way that is noticeable to others.",
//       ],
//       "6_mdd_fatigue": [
//         "Yes",
//         "The patient feels exhausted and spends the day on the sofa unless they have to go to work.",
//       ],
//       "7_mdd_guilt": [
//         "Yes",
//         "The patient expresses feelings of worthlessness, saying 'I'm just a burden to everybody really'.",
//       ],
//       "8_mdd_concentration": [
//         "Yes",
//         "The patient has trouble concentrating, forgetting things like swimming money and parent's evening, and losing focus while watching TV.",
//       ],
//       "9_mdd_suicide": [
//         "Yes",
//         "The patient mentions having thoughts about it being 'easy' to harm themselves when alone at night, though they deny making any plans.",
//       ],
//       "10_mdd_symptom_count": [
//         "Yes",
//         "The patient experiences at least 5 of the above symptoms during the same period, with depressed mood and loss of interest present.",
//       ],
//       "11_mdd_impairment": [
//         "Yes",
//         "The symptoms cause significant impairment in the patient's daily functioning, struggling to care for their children and having problems at work.",
//       ],
//       "12_mdd_exclusion_medical": [
//         "Yes",
//         "There is no indication that the symptoms are due to a medical condition, medication, or substance use.",
//       ],
//       "13_mdd_exclusion_schizo": [
//         "Yes",
//         "There is no mention of symptoms related to schizophrenia or other psychotic disorders.",
//       ],
//       "14_mdd_hypomanic": [
//         "Yes",
//         "There is no indication of the patient having experienced a manic or hypomanic episode.",
//       ],
//       "15_mdd_episode_type": [
//         "Yes",
//         "The patient mentions a previous episode when their husband left about 4 years ago, suggesting this is a recurrent episode.",
//       ],
//       "16a_mdd_severity_mild": [
//         "No",
//         "The severity does not appear to be mild given the significant impairment.",
//       ],
//       "16b_mdd_severity_moderate": [
//         "Yes",
//         "The symptoms seem to be of moderate severity.",
//       ],
//       "16c_mdd_severity_severe": [
//         "No",
//         "There is no indication of psychotic features.",
//       ],
//       "17a_mdd_remission_partial": [
//         "No",
//         "There is no indication that the patient is in partial remission.",
//       ],
//       "17b_mdd_remission_full": [
//         "No",
//         "There is no indication that the patient is in full remission.",
//       ],
//       "18_mdd_distress": [
//         "No",
//         "There is no mention of the patient exhibiting anxious distress.",
//       ],
//       "19_mdd_mixed": [
//         "No",
//         "There is no indication of the patient exhibiting mixed features.",
//       ],
//       "20_mdd_melancholic": [
//         "No",
//         "There is no clear mention of the patient exhibiting melancholic features.",
//       ],
//       "21_mdd_atypical": [
//         "No",
//         "There is no mention of the patient exhibiting atypical features.",
//       ],
//       "22_mdd_psychotic": [
//         "No",
//         "There is no indication of the patient exhibiting psychotic features.",
//       ],
//       "23_mdd_catatonia": [
//         "No",
//         "There is no mention of the patient exhibiting catatonia.",
//       ],
//       "24_mdd_peripartum": [
//         "No",
//         "There is no mention of the symptoms being related to pregnancy or the postpartum period.",
//       ],
//       "25_mdd_seasonal": [
//         "No",
//         "There is no mention of the depressive episodes occurring in a seasonal pattern.",
//       ],
//     },
//   ],
//   [
//     "summary_sud",
//     {
//       chronic_pain: [],
//       cultural_belief: [],
//       motivation: [
//         "Low",
//         "The patient does not express a strong desire or motivation to stop or reduce their substance use. They acknowledge feeling 'useless' and struggling with various aspects of life, but do not explicitly state a willingness to change their habits.",
//       ],
//       readiness: [
//         "Low",
//         "The patient does not directly express readiness to engage in treatment or seek help. While they mention knowing they can come to the doctor if needed, there is no clear indication of readiness to actively pursue treatment or rehabilitation.",
//       ],
//       coping_strategy: [],
//       past_coping_strategy: [],
//       relapse: ["No"],
//       substance: ["Alcohol"],
//       secondary_substances: [],
//       substance_category: ["alcohol"],
//       frequency: ["No Info"],
//       administration: ["Oral"],
//       substance_start: ["No Info"],
//       control_usage: ["No"],
//       control_desire: ["No"],
//       control_commitment: ["No"],
//       control_arduous: ["No"],
//       social_failure: [
//         "Yes",
//         "The patient mentions struggling to cope with things, not being able to do as much with the kids, and just spending the day on the sofa, suggesting a failure to fulfill parental and household obligations.",
//       ],
//       social_persistence: ["No"],
//       social_decline: [
//         "Yes",
//         "The patient states that they used to do a lot with the kids, go swimming and playing, but now just spend the day on the sofa, indicating a decline in social and recreational activities.",
//       ],
//       risk_hazard: ["No"],
//       risk_tenacity: ["No"],
//       tolerance_dosage_increase: ["No"],
//       tolerance_tolerance: ["No"],
//       withdrawal_relief: ["No"],
//       withdrawal_symptoms: ["No"],
//       remission_sud: ["No"],
//       "1_dsm_control_usage": [
//         "No",
//         "There is no explicit mention of the patient using alcohol in larger amounts or for longer periods than intended.",
//       ],
//       "2_dsm_control_desire": [
//         "No",
//         "The patient does not express a desire or unsuccessful efforts to cut down or control their alcohol use.",
//       ],
//       "3_dsm_control_commitment": [
//         "No",
//         "There is no indication that the patient spends significant time or effort obtaining or recovering from the effects of alcohol.",
//       ],
//       "4_dsm_control_arduous": [
//         "No",
//         "The patient does not mention experiencing cravings or a strong urge to use alcohol.",
//       ],
//       "5_dsm_social_failure": [
//         "Yes",
//         "The patient mentions struggling to cope with things, not being able to do as much with the kids, and just spending the day on the sofa, suggesting a failure to fulfill parental and household obligations due to their low mood and struggles.",
//       ],
//       "6_dsm_social_persistence": [
//         "No",
//         "There is no mention of the patient continuing to use alcohol despite experiencing persistent social or interpersonal problems caused by its effects.",
//       ],
//       "7_dsm_social_decline": [
//         "Yes",
//         "The patient states that they used to do a lot with the kids, go swimming and playing, but now just spend the day on the sofa, indicating a decline in social and recreational activities.",
//       ],
//       "8_dsm_risk_hazard": [
//         "No",
//         "There is no indication that the patient uses alcohol in hazardous situations.",
//       ],
//       "9_dsm_risk_tenacity": [
//         "No",
//         "The patient does not mention continuing to use alcohol despite being aware of physical or psychological difficulties it is causing.",
//       ],
//       "10_a_dsm_tolerance_dosage_increase": [
//         "No",
//         "There is no mention of the patient needing increased amounts of alcohol to achieve the desired effect.",
//       ],
//       "10_b_dsm_tolerance_tolerance": [
//         "No",
//         "There is no indication that the patient experiences diminished effects from using the same amount of alcohol.",
//       ],
//       "11_a_dsm_withdrawal_relief": [
//         "No",
//         "There is no mention of the patient using alcohol to relieve or avoid withdrawal symptoms after a cessation or reduction in use.",
//       ],
//       "11_b_dsm_withdrawal_symptoms": [
//         "No",
//         "The patient does not report experiencing withdrawal symptoms after reducing or stopping alcohol use.",
//       ],
//       "10_dsm_tolerance": ["No"],
//       "11_dsm_withdrawal": ["No"],
//       "10_c_dsm_tolerance_symptoms": ["No", "No Info provided"],
//     },
//   ],
// ];
// const staticDignosisAPIResponseFormat = [
//   {
//     sud: {
//       score_sud: 2,
//       severity_sud: "mild",
//       remission: ["No"],
//       main_substance: ["alcohol"],
//       secondary_substances: [],
//       diagnosis: [
//         // {
//         //   disorder: "alcohol use disorder",
//         //   severity: "mild",
//         //   code: "F10.10",
//         // },
//       ],
//       withdrawal: [],
//     },
//     sud_induced: {
//       diagnosis: [
//         // {
//         //   disorder: "alcohol use disorder",
//         //   severity: "mild",
//         //   code: "F10.10",
//         // },
//       ],
//     },
//     mdd: {
//       diagnosis: [
//         // {
//         //   disorder: "alcohol use disorder",
//         //   severity: "mild",
//         //   code: "F10.10",
//         // },
//       ],
//     },
//     gad: {
//       diagnosis: [
//         // {
//         //   disorder: "Major Depressive Disorder, recurrent, moderate",
//         //   severity: "moderate",
//         //   episode: "recurrent",
//         //   remission: "no remission",
//         //   psychotic: "without psychotic features",
//         //   code: "F33.1",
//         // },
//       ],
//     },
//   },
// ];
const SessionReport = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { adminPractitionerId, role } = useAuth();
  const { patientDetails, sessionid } = location.state || {};
  const sessionData = localStorage.getItem("userData");
  const practionerDetails = sessionData ? JSON.parse(sessionData) : {};
  const [duration, setDuration] = useState<string>("");
  const sessionTranscriptintervalIdRef = useRef<number | null>(null);
  const sessionSummaryintervalIdRef = useRef<number | null>(null);
  // const sessionAIDADignosisintervalIdRef = useRef<number | null>(null);
  const sessionTreatmentPlanintervalIdRef = useRef<number | null>(null);
  const [sessionDate, setSessionDate] = useState("-");
  const [sesssionTime, setSessionTime] = useState("-");

  // Session Transcript
  const [sessionTranscriptList, setSessionTranscriptList] = useState<any[]>([]);
  const [sessionTranscriptLoading] = useState<boolean>(false);
  const [sessionTranscriptisExpanded, setSessionTranscriptisExpanded] =
    useState(false);
  // const [feedbackSubmitDisabled, setFeedbackSubmitDisabled] = useState(false);

  // Session Summary
  const [sessionSummaryList, setSessionSummaryList] = useState<any[]>([]);
  const [sessionSummaryLoading] = useState<boolean>(false);
  const [sessionSummaryIsExpanded, setsessionSummaryIsExpanded] =
    useState(false);
  const [sessionSummaryListData, setSessionSummaryListData] = useState<
    SummaryEntry[]
  >([]);
  const [sessionSummaryGeneralData, setSessionSummaryGeneralData] =
    useState<any>({});
  const [sessionSummarySudData, setSessionSummarySudData] = useState<any>({});
  const [sessionSummaryType, setSessionSummaryType] = useState("");

  // Diagnosis
  const [diagnosisList, setDiagnosisList] = useState<any[]>([]);
  const [diagnosisLoading] = useState<boolean>(false);
  const [diagnosisIsExpanded, setDiagnosisIsExpanded] = useState(false);
  // const [sessionDigosisData, setSessionDignosisData] = useState([]);
  const [availableDignosisTypeList, setAvailableDignosisTypeList] = useState<
    String[]
  >([]);
  const [isAllDignosisEmpty, setIsAllDignosisEmpty] = useState(false);

  // Treatment plan
  const [treatmentPlanList, setTreatmentPlanList] = useState<any[]>([]);
  const [treatmentLoading] = useState<boolean>(false);
  const [treatmentIsExpanded, setTreatmentPlanIsExpanded] = useState(false);
  const [pharmacologicalMedicationList, setpharmacologicalMedicationList] =
    useState<any[]>([]);
  const [
    nonPharmacologicalMedicationList,
    setNonPharmacologicalMedicationList,
  ] = useState<any[]>([]);

  const [currentExpandedReport, setCurrentExpandedReport] = useState<
    null | number
  >(null);

  const [diagnoDetailsSummary, setDiagnoDetailsSummary] = useState<
    SessionDignosisType[]
  >([]);

  // let [identInfo, setIdentInfo] = useState<any>({
  //   visit: "NA",
  //   education: "NA",
  //   employment: "NA",
  // });
  // let [patientInfo, setPatientInfo] = useState<any>({
  //   complaint: "NA",
  //   firearm: "NA",
  //   legal_problem: "NA",
  //   past_psychiatric_history: "NA",
  //   violence: "NA",
  //   gender_issue: "NA",
  //   child_trauma: "NA",
  //   relationship: "NA",
  //   friends: "NA",
  //   previous_medical_history: "NA",
  //   liver_problem: "NA",
  //   heart_problem: "NA",
  //   vitamin_deficiency: "NA",
  //   infection: "NA",
  //   drug_allergy: "NA",
  //   medicaiton: "NA",
  //   mental_health: "NA",
  //   other_mental_health: "NA",
  //   cognitive_history: "NA",
  //   cognitive_functioning: "NA",
  //   suicide_attempt: "NA",
  //   suicide_ideation: "NA",
  //   safety: "NA",
  // });

  // let [substanceInfo, setSubstanceInfo] = useState<any>({
  //   substance: "NA",
  //   secondary_substances: "NA",
  //   frequency: "NA",
  //   administration: "NA",
  //   substance_start: "NA",
  //   chronic_pain: "NA",
  //   cultural_belief: "NA",
  //   motivation: "NA",
  //   readiness: "NA",
  //   coping_strategy: "NA",
  //   past_coping_strategy: "NA",
  //   withdrawal: "NA",
  //   relapse: "NA",
  //   overdose: "NA",
  // });
  // let [diagnosInfo, setDiagnosInfo] = useState<any>({
  //   framework: "NA",
  //   diagnosis: "NA",
  //   secondary_diagnosis: "NA",
  //   lab_work: "NA",
  // });
  // let [treatmentInfo, setTreatmentInfo] = useState<any>({
  //   treatment: "NA",
  //   medicaiton: "NA",
  //   pregnancy: "NA",
  //   follow_up: "NA",
  // });
  // let [aidaTouchInfo, setAidaTouchInfo] = useState<any>({
  //   vocabulary: "NA",
  // });

  let [diagnoDetailInfo, setDiagnoDetailInfo] = useState<any>([]);

  // let [diagnoDetailsServerityList, setDiagnoDetailsServerityList] =
  //   useState<any>([]);

  // let [priDiagnoRefInfo, setPriDiagnoRefInfo] = useState<any>({
  //   "1_dsm_control_usage": "NA",
  //   "2_dsm_control_desire": "NA",
  //   "3_dsm_control_commitment": "NA",
  //   "4_dsm_control_arduous": "NA",
  //   "5_dsm_social_failure": "NA",
  //   "6_dsm_social_persistence": "NA",
  //   "7_dsm_social_decline": "NA",
  //   "8_dsm_risk_hazard": "NA",
  //   "9_dsm_risk_tenacity": "NA",
  //   "10_dsm_tolerance": "NA",
  //   "10_a_dsm_tolerance_dosage_increase": "NA",
  //   "10_b_dsm_tolerance_tolerance": "NA",
  //   "10_c_dsm_tolerance_symptoms": "NA",
  //   "11_dsm_withdrawal": "NA",
  //   "11_a_dsm_withdrawal_relief": "NA",
  //   "11_b_dsm_withdrawal_symptoms": "NA",
  // });

  let [treatmentDetailInfo, setTreatmentDetailInfo] = useState<any>({
    psychotherapy: "NA",
    substance_cessation: "NA",
    supportive_measures: "NA",
    pharmacotherapy: "NA",
    indication: "NA",
    fda_pregnancy_categories: "NA",
    breastfeeding_warnings: "NA",
    contraindications: "NA",
    age_warnings: "NA",
  });

  const handleViewMore = (menu: string) => {
    if (menu === "session_transcript") {
      setSessionTranscriptisExpanded((prev) => !prev);
      setCurrentExpandedReport(1);
    } else if (menu === "session_summary") {
      setsessionSummaryIsExpanded((prev) => !prev);
      setCurrentExpandedReport(2);
    } else if (menu === "diagosis") {
      setDiagnosisIsExpanded((prev) => !prev);
      setCurrentExpandedReport(3);
    } else if (menu === "treatment_plan") {
      setTreatmentPlanIsExpanded((prev) => !prev);
      setCurrentExpandedReport(4);
    }
  };

  const feedbackExpanded = (reportid: number) => {
    if (reportid === 1) {
      setSessionTranscriptisExpanded((prev) => !prev);
    } else if (reportid === 2) {
      setsessionSummaryIsExpanded((prev) => !prev);
    } else if (reportid === 3) {
      setDiagnosisIsExpanded((prev) => !prev);
    } else if (reportid === 4) {
      setTreatmentPlanIsExpanded((prev) => !prev);
    }
  };

  useEffect(() => {
    getSessionDetails();
    fetchSessionTranscript();
    fetchSessionSummary();
    fetchSessionAIDATreatmentPlan();
    // fetchSessionAIDADignosis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let sessionTranscriptAttemptCount = 0; // Initialize attempt counter
    let sessionSummaryAttemptCount = 0;
    // let sessionAIDADignosisAttemptCount = 0;
    let sessionTreatmentAttemptCount = 0;

    sessionTranscriptintervalIdRef.current = window.setInterval(async () => {
      sessionTranscriptAttemptCount++;

      const apiResponse = await fetchSessionTranscript();

      if (
        apiResponse ||
        sessionTranscriptAttemptCount >= sessionReportMaxAttemptCount
      ) {
        clearInterval(sessionTranscriptintervalIdRef.current!); // Clear interval after 10 attempts or success
        sessionTranscriptintervalIdRef.current = null; // Reset intervalRef
      }
    }, sessionAutoRefreshTiming); // 60000 milliseconds = 1 minute

    sessionSummaryintervalIdRef.current = window.setInterval(async () => {
      sessionSummaryAttemptCount++;

      const apiResponse = await fetchSessionSummary();
      fetchSessionAIDADignosis();

      if (
        apiResponse ||
        sessionSummaryAttemptCount >= sessionReportMaxAttemptCount
      ) {
        clearInterval(sessionSummaryintervalIdRef.current!); // Clear interval after 10 attempts or success
        sessionSummaryintervalIdRef.current = null; // Reset intervalRef
      }
    }, sessionAutoRefreshTiming); // 60000 milliseconds = 1 minute

    // sessionAIDADignosisintervalIdRef.current = window.setInterval(async () => {
    //   sessionAIDADignosisAttemptCount++;

    //   const apiResponse = await fetchSessionAIDADignosis();

    //   if (
    //     apiResponse ||
    //     sessionAIDADignosisAttemptCount >= sessionReportMaxAttemptCount
    //   ) {
    //     clearInterval(sessionAIDADignosisintervalIdRef.current!); // Clear interval after 10 attempts or success
    //     sessionAIDADignosisintervalIdRef.current = null; // Reset intervalRef
    //   }
    // }, sessionAutoRefreshTiming); // 60000 milliseconds = 1 minute

    sessionTreatmentPlanintervalIdRef.current = window.setInterval(async () => {
      sessionTreatmentAttemptCount++;

      const apiResponse = await fetchSessionAIDATreatmentPlan();

      if (
        apiResponse ||
        sessionTreatmentAttemptCount >= sessionReportMaxAttemptCount
      ) {
        clearInterval(sessionTreatmentPlanintervalIdRef.current!); // Clear interval after 10 attempts or success
        sessionTreatmentPlanintervalIdRef.current = null; // Reset intervalRef
      }
    }, sessionAutoRefreshTiming); // 60000 milliseconds = 1 minute

    // Cleanup on unmount
    return () => {
      if (sessionTranscriptintervalIdRef.current !== null) {
        clearInterval(sessionTranscriptintervalIdRef.current);
      }

      if (sessionSummaryintervalIdRef.current !== null) {
        clearInterval(sessionSummaryintervalIdRef.current);
      }

      // if (sessionAIDADignosisintervalIdRef.current !== null) {
      //   clearInterval(sessionAIDADignosisintervalIdRef.current);
      // }

      if (sessionTreatmentPlanintervalIdRef.current !== null) {
        clearInterval(sessionTreatmentPlanintervalIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSessionTranscript = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/transcriptions/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionTranscriptAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/transcriptions/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionTranscriptAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };
  // Function to process the API response
  const processSessionTranscriptAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const mergedAudioSegments = APIResponse.flatMap(
        (item: { results: { audio_segments: any } }) =>
          item.results.audio_segments
      );
      if (mergedAudioSegments.length) {
        setSessionTranscriptList(mergedAudioSegments);
        return true; // API response is not empty
      } else {
        return false; // API response is empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setSessionTranscriptList([APIResponse]);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const getSessionDetails = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/session/${adminPractitionerId}/${sessionid}`
        );
        if (APIResponse) {
          const result = splitDateTime(APIResponse.audio_created_at);
          const duration = findDuration(
            APIResponse.audio_created_at,
            APIResponse.session_end_dttm
          );
          if (duration) {
            setDuration(duration);
          }
          if (result && result.date && result.time) {
            setSessionDate(result.date);
            setSessionTime(result.time);
          }
        }
      } else {
        const APIResponse = await getData(
          `/session/${practionerDetails.practionerInfo.id}/${sessionid}`
        );
        if (APIResponse) {
          const duration = findDuration(
            APIResponse.audio_created_at,
            APIResponse.session_end_dttm
          );
          if (duration) {
            setDuration(duration);
          }
          const result = splitDateTime(APIResponse.audio_created_at);
          if (result && result.date && result.time) {
            setSessionDate(result.date);
            setSessionTime(result.time);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
    }
  };

  const fetchSessionSummary = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/summaries/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionSummaryAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/summaries/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionSummaryAPIResponse(APIResponse);
        // return processSessionSummaryAPIResponse(staticSummaryAPIResponseFormat);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionSummaryAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const sessionSummary = transformData(APIResponse);
      if (sessionSummary.length) {
        setSessionSummaryList(sessionSummary);
        setSessionSummaryData(sessionSummary);
        confirmSessionSummaryType(APIResponse);
        return true; // API response is not empty
      } else {
        return false; // API response is empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setSessionSummaryList([APIResponse]);
      setSessionSummaryData([APIResponse]);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const confirmSessionSummaryType = async (summaryData: SummaryEntry[]) => {
    try {
      // Find the "summary_general" entry
      const summaryGeneralEntry = summaryData.find(
        ([key]) => key === "summary_general"
      );
      // Extract the object if the entry is found
      const summaryGeneralValue = summaryGeneralEntry
        ? summaryGeneralEntry[1]
        : null;
      const summaryType =
        summaryGeneralValue?.patient_category?.[0] ?? "Mental";
      setSessionSummaryType(summaryType);

      setSessionSummaryGeneralData(summaryGeneralValue);

      // Find the "summary_sud" entry
      const summarySudEntry = summaryData.find(
        ([key]) => key === "summary_sud"
      );
      // Extract the object if the entry is found
      const summarySudValue = summarySudEntry ? summarySudEntry[1] : null;
      setSessionSummarySudData(summarySudValue);
      setSessionSummaryListData(summaryData);
      fetchSessionAIDADignosis();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSessionAIDADignosis = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/diagnosis/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDADignosisAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/diagnosis/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDADignosisAPIResponse(APIResponse);
        // return processSessionAIDADignosisAPIResponse(
        //   staticDignosisAPIResponseFormat
        // );
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionAIDADignosisAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const sessionSummary = transformData(APIResponse);
      if (sessionSummary.length) {
        setDiagnosisList(sessionSummary);
        setDiagnosisData(sessionSummary);
        return true; // API response is not empty
      } else {
        return false; // API response is not empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setDiagnosisList([APIResponse]);
      setDiagnosisData([APIResponse]);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const fetchSessionAIDATreatmentPlan = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/treatment/${adminPractitionerId}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDATreatmenPlantAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/treatment/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionid}`
        );
        return processSessionAIDATreatmenPlantAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionAIDATreatmenPlantAPIResponse = (
    APIResponse: any
  ): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const isNoTreatment = checkNoTreatement(APIResponse);
      const treatmentPlan = transformData(APIResponse);
      if (isNoTreatment) {
        setTreatmentPlanList(treatmentPlan);
        setTreatmentPlanData(treatmentPlan);
        return true; // No Need call Chunking API
      }
      if (treatmentPlan.length) {
        setTreatmentPlanList(treatmentPlan);
        setTreatmentPlanData(treatmentPlan);
        return true; // API response is not empty
      } else {
        return false;
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setTreatmentPlanList([APIResponse]);
      setTreatmentPlanData(treatmentPlanList);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const transformData = (input: DataItem[]): DataItem[] => {
    return input.reduce((acc: DataItem[], obj: DataItem) => {
      Object.keys(obj).forEach((key: string) => {
        acc.push({ [key]: obj[key] });
      });
      return acc;
    }, []);
  };

  const setSessionSummaryData = (sessionSummary: any) => {
    let identiObj: any = {};
    let patientObj: any = {};
    let substanceObj: any = {};
    let diagnosObj: any = {};
    let treatmentObj: any = {};
    let aidaTouchObj: any = {};
    let priDiagnoObj: any = {};
    // let obj = {};
    sessionSummary.map((sessionData: any) => {
      Object.entries(sessionData).map(([key, value]) => {
        if (key === "visit") {
          identiObj["visit"] = value;
        }
        if (key === "education") {
          identiObj["education"] = value;
        }
        if (key === "employment") {
          identiObj["employment"] = value;
        }
        if (key === "complaint") {
          patientObj["complaint"] = value;
        }
        if (key === "firearm") {
          patientObj["firearm"] = value;
        }
        if (key === "legal_problem") {
          patientObj["legal_problem"] = value;
        }
        if (key === "past_psychiatric_history") {
          patientObj["past_psychiatric_history"] = value;
        }
        if (key === "violence") {
          patientObj["violence"] = value;
        }
        if (key === "gender_issue") {
          patientObj["gender_issue"] = value;
        }
        if (key === "child_trauma") {
          patientObj["child_trauma"] = value;
        }
        if (key === "relationship") {
          patientObj["relationship"] = value;
        }
        if (key === "friends") {
          patientObj["friends"] = value;
        }
        if (key === "previous_medical_history") {
          patientObj["previous_medical_history"] = value;
        }
        if (key === "liver_problem") {
          patientObj["liver_problem"] = value;
        }
        if (key === "heart_problem") {
          patientObj["heart_problem"] = value;
        }
        if (key === "vitamin_deficiency") {
          patientObj["vitamin_deficiency"] = value;
        }
        if (key === "infection") {
          patientObj["infection"] = value;
        }
        if (key === "drug_allergy") {
          patientObj["drug_allergy"] = value;
        }
        if (key === "medicaiton") {
          patientObj["medicaiton"] = value;
        }
        if (key === "mental_health") {
          patientObj["mental_health"] = value;
        }
        if (key === "other_mental_health") {
          patientObj["other_mental_health"] = value;
        }
        if (key === "cognitive_history") {
          patientObj["cognitive_history"] = value;
        }
        if (key === "cognitive_functioning") {
          patientObj["cognitive_functioning"] = value;
        }
        if (key === "suicide_attempt") {
          patientObj["suicide_attempt"] = value;
        }
        if (key === "suicide_ideation") {
          patientObj["suicide_ideation"] = value;
        }
        if (key === "safety") {
          patientObj["safety"] = value;
        }

        if (key === "substance") {
          substanceObj["substance"] = value;
        }
        if (key === "secondary_substances") {
          substanceObj["secondary_substances"] = value;
        }
        if (key === "frequency") {
          substanceObj["frequency"] = value;
        }
        if (key === "administration") {
          substanceObj["administration"] = value;
        }
        if (key === "substance_start") {
          substanceObj["substance_start"] = value;
        }
        if (key === "chronic_pain") {
          substanceObj["chronic_pain"] = value;
        }
        if (key === "cultural_belief") {
          substanceObj["cultural_belief"] = value;
        }
        if (key === "motivation") {
          substanceObj["motivation"] = value;
        }
        if (key === "readiness") {
          substanceObj["readiness"] = value;
        }
        if (key === "coping_strategy") {
          substanceObj["coping_strategy"] = value;
        }
        if (key === "past_coping_strategy") {
          substanceObj["past_coping_strategy"] = value;
        }
        if (key === "withdrawal") {
          substanceObj["withdrawal"] = value;
        }
        if (key === "relapse") {
          substanceObj["relapse"] = value;
        }
        if (key === "overdose") {
          substanceObj["overdose"] = value;
        }

        if (key === "framework") {
          diagnosObj["framework"] = value;
        }
        if (key === "diagnosis") {
          diagnosObj["diagnosis"] = value;
        }
        if (key === "secondary_diagnosis") {
          diagnosObj["secondary_diagnosis"] = value;
        }
        if (key === "lab_work") {
          diagnosObj["lab_work"] = value;
        }

        if (key === "treatment") {
          treatmentObj["treatment"] = value;
        }
        if (key === "medicaiton") {
          treatmentObj["medicaiton"] = value;
        }
        if (key === "pregnancy") {
          treatmentObj["pregnancy"] = value;
        }
        if (key === "follow_up") {
          treatmentObj["follow_up"] = value;
        }

        if (key === "vocabulary") {
          aidaTouchObj["vocabulary"] = value;
        }

        if (key === "1_dsm_control_usage") {
          priDiagnoObj["1_dsm_control_usage"] = value;
        }
        if (key === "2_dsm_control_desire") {
          priDiagnoObj["2_dsm_control_desire"] = value;
        }
        if (key === "3_dsm_control_commitment") {
          priDiagnoObj["3_dsm_control_commitment"] = value;
        }
        if (key === "4_dsm_control_arduous") {
          priDiagnoObj["4_dsm_control_arduous"] = value;
        }
        if (key === "5_dsm_social_failure") {
          priDiagnoObj["5_dsm_social_failure"] = value;
        }
        if (key === "6_dsm_social_persistence") {
          priDiagnoObj["6_dsm_social_persistence"] = value;
        }
        if (key === "7_dsm_social_decline") {
          priDiagnoObj["7_dsm_social_decline"] = value;
        }
        if (key === "8_dsm_risk_hazard") {
          priDiagnoObj["8_dsm_risk_hazard"] = value;
        }
        if (key === "9_dsm_risk_tenacity") {
          priDiagnoObj["9_dsm_risk_tenacity"] = value;
        }
        if (key === "10_dsm_tolerance") {
          priDiagnoObj["10_dsm_tolerance"] = value;
        }
        if (key === "10_a_dsm_tolerance_dosage_increase") {
          priDiagnoObj["10_a_dsm_tolerance_dosage_increase"] = value;
        }
        if (key === "10_b_dsm_tolerance_tolerance") {
          priDiagnoObj["10_b_dsm_tolerance_tolerance"] = value;
        }
        if (key === "10_c_dsm_tolerance_symptoms") {
          priDiagnoObj["10_c_dsm_tolerance_symptoms"] = value;
        }
        if (key === "11_dsm_withdrawal") {
          priDiagnoObj["11_dsm_withdrawal"] = value;
        }
        if (key === "11_a_dsm_withdrawal_relief") {
          priDiagnoObj["11_a_dsm_withdrawal_relief"] = value;
        }
        if (key === "11_b_dsm_withdrawal_symptoms") {
          priDiagnoObj["11_b_dsm_withdrawal_symptoms"] = value;
        }
        return sessionData;
      });
      return sessionData;
    });
    // identInfo = identiObj;
    // patientInfo = patientObj;
    // substanceInfo = substanceObj;
    // treatmentInfo = treatmentObj;
    // diagnosInfo = diagnosObj;
    // aidaTouchInfo = aidaTouchObj;
    // priDiagnoRefInfo = priDiagnoObj;
    // setIdentInfo(identiObj);
    // setPatientInfo(patientObj);
    // setSubstanceInfo(substanceObj);
    // setDiagnosInfo(diagnosObj);
    // setTreatmentInfo(treatmentObj);
    // setAidaTouchInfo(aidaTouchObj);
    // setPriDiagnoRefInfo(priDiagnoObj);
  };

  const setDiagnosisData = (diagnosisData: any) => {
    const sortOrder = ["mdd", "gad", "sud", "sud-induced"];
    let primaryDignosisarray: any = [];
    let availableDignosisTypeListArray: String[] = [];
    diagnosisData.map((diagnosisData: any) => {
      const keysWithMultipleChildren: { parentKey: string; value: any }[] = [];
      // eslint-disable-next-line array-callback-return
      Object.entries(diagnosisData).map(([key, value]: [string, any]) => {
        if (typeof value === "object" && value !== null) {
          const diagnosisArray = value.diagnosis;
          if (diagnosisArray.length) {
            availableDignosisTypeListArray.push(key);
            let disorder: any = [];
            let code: any = [];
            let severity: any = [];
            diagnosisArray.map((diagno: any) => {
              disorder = [...disorder, diagno.disorder];
              code = [...code, diagno.code];
              severity = [...severity, diagno.severity];
              diagno.type = key;
              primaryDignosisarray.push(diagno);
              return diagno;
            });
          }
        }
      });
      return keysWithMultipleChildren;
    });

    const dignosisCodeList = primaryDignosisarray
      .sort(
        (a: { type: string }, b: { type: string }) =>
          sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type)
      )
      .map((item: { code: any }) => item.code);

    primaryDignosisarray.sort((a: { type: string }, b: { type: string }) => {
      return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
    });
    setDiagnoDetailsSummary(primaryDignosisarray);
    setDiagnoDetailInfo(dignosisCodeList);
    const checkDignosisLength = isAnyDiagnosisNotEmpty(diagnosisData);
    setIsAllDignosisEmpty(!checkDignosisLength);
    // setDiagnoDetailsServerityList(dignosisSeverityList);
    setAvailableDignosisTypeList(availableDignosisTypeListArray);
    // console.log(sessionSummaryListData)
  };

  const isAnyDiagnosisNotEmpty = (diagnosisData: any) => {
    return diagnosisData.some(
      (entry: ArrayLike<unknown> | { [s: string]: unknown }) =>
        Object.values(entry).some(
          (section: any) =>
            Array.isArray(section.diagnosis) && section.diagnosis.length > 0
        )
    );
  };

  const setTreatmentPlanData = (treatmentPlan: any) => {
    let treatmentDetailObj: any = {};
    let psychotherapy: any = [];
    let pharmacotherapy: any = [];
    let substance_cessation: any = [];
    let indication: any = [];
    let fda_pregnancy_categories: any = [];
    let supportive_measures: any = [];
    let breastfeeding_warnings: any = [];
    let contraindications: any = [];
    let age_warnings: any = [];
    let pharmacologicalMedicationData: any[] = [];
    let nonPharmacologicalMedicationData: any[] = [];
    Object.values(treatmentPlan).map((obj: any) => {
      Object.entries(obj).map(([key, value]: any) => {
        const tmpObj: any = {};
        tmpObj.code = key;
        if (value.psychotherapy !== "N/A") {
          tmpObj.psychotherapy = value.psychotherapy;
          psychotherapy = [...psychotherapy, value.psychotherapy];
        }
        if (value.substance_cessation !== "N/A") {
          tmpObj.substance_cessation = value.substance_cessation;
          substance_cessation = [
            ...substance_cessation,
            value.substance_cessation,
          ];
        }
        if (value.supportive_measures !== "N/A") {
          tmpObj.supportive_measures = value.supportive_measures;
          supportive_measures = [
            ...supportive_measures,
            value.supportive_measures,
          ];
        }
        if (
          value?.medication &&
          value?.medication &&
          value?.medication.length > 0
        ) {
          value?.medication.map((medica: any) => {
            medica.code = key;
            pharmacologicalMedicationData.push(medica);
            pharmacotherapy = [...pharmacotherapy, medica.pharmacotherapy];
            indication = [...indication, medica.indication];
            fda_pregnancy_categories = [
              ...fda_pregnancy_categories,
              medica.fda_pregnancy_categories,
            ];
            breastfeeding_warnings = [
              ...breastfeeding_warnings,
              medica.breastfeeding_warnings,
            ];
            contraindications = [
              ...contraindications,
              medica.contraindications,
            ];
            age_warnings = [...age_warnings, medica.age_warnings];
            return medica;
          });
          nonPharmacologicalMedicationData.push(tmpObj);
        }
        return value;
      });
      return obj;
    });
    treatmentDetailObj.psychotherapy = psychotherapy;
    treatmentDetailObj.substance_cessation = substance_cessation;
    treatmentDetailObj.supportive_measures = supportive_measures;
    treatmentDetailObj.supportive_measures = psychotherapy;
    treatmentDetailObj.pharmacotherapy = pharmacotherapy;
    treatmentDetailObj.indication = indication;
    treatmentDetailObj.fda_pregnancy_categories = fda_pregnancy_categories;
    treatmentDetailObj.breastfeeding_warnings = breastfeeding_warnings;
    treatmentDetailObj.contraindications = contraindications;
    treatmentDetailObj.age_warnings = age_warnings;
    treatmentDetailInfo = treatmentDetailObj;
    setpharmacologicalMedicationList(pharmacologicalMedicationData);
    setNonPharmacologicalMedicationList(nonPharmacologicalMedicationData);
    setTreatmentDetailInfo(treatmentDetailObj);
  };

  const checkNoTreatement = (response: any) => {
    const expectedResponse = [
      {
        Treatment:
          "There is currently no treatment plan available for this session.",
      },
    ];
    const isSameResponse =
      JSON.stringify(response) === JSON.stringify(expectedResponse);

    return isSameResponse;
  };

  const downloadTxtFile = (menu: string) => {
    // Step 1: Create the content for the file
    // Create text content dynamically using map
    let content = "";
    let fileName = "sample.txt";
    if (menu === "session_transcript") {
      content = sessionTranscriptList
        .map((item, i) => `${item.speaker_label}: ${item.transcript}`)
        .join("\n"); // Join each transcript item with a new line

      fileName = `${patientDetails.first_name}-${patientDetails.last_name} SessionTranscript.txt`;
    } else if (menu === "session_treatment_plan") {
      content =
        document.getElementById("sessionTreatmentPlanDiv")?.innerText ||
        "There is No Session Treatement found.";

      fileName = `${patientDetails.first_name}-${patientDetails.last_name} AIDATreatmentPlan.txt`;
    } else if (menu === "session_summary") {
      content =
        document.getElementById("sessionSummaryDiv")?.innerText ||
        "There is No Session Summary found.";
      fileName = `${patientDetails.first_name}-${patientDetails.last_name} Session-Notes.txt`;
    } else if (menu === "aida_dignosis") {
      content =
        document.getElementById("sessionDignosisDiv")?.innerText ||
        "There is No Session Dignosis found.";

      fileName = `${patientDetails.first_name}-${patientDetails.last_name} AIDA-Diagnosis.txt`;
    }

    // Step 2: Create a Blob object with the content as plain text
    const blob = new Blob([content], { type: "text/plain" });

    // Step 3: Create a URL for the Blob
    const fileUrl = URL.createObjectURL(blob);

    // Step 4: Create an anchor element and simulate a click
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName; // Name of the file with .txt extension
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(fileUrl); // Revoke the object URL after download
  };

  const handleBack = () => {
    if (sessionSummaryIsExpanded) {
      setsessionSummaryIsExpanded(false);
    } else if (diagnosisIsExpanded) {
      setDiagnosisIsExpanded(false);
    } else if (treatmentIsExpanded) {
      setTreatmentPlanIsExpanded(false);
    } else {
      setSessionTranscriptisExpanded(false);
    }
    if (
      !sessionSummaryIsExpanded &&
      !diagnosisIsExpanded &&
      !treatmentIsExpanded &&
      !sessionTranscriptisExpanded
    ) {
      navigate("/patient-details", {
        state: { patientDetails: patientDetails },
      });
    }
  };

  return (
    <AppDrawer>
      <Paper
        variant="outlined"
        component="fieldset"
        sx={{
          backgroundColor: "#ffcc00",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mb: 3, fontSize: "medium" }}
            >
              {patientDetails?.first_name ? patientDetails.first_name : "-"}{" "}
              {patientDetails?.last_name ? patientDetails.last_name : "-"}
              <Divider
                sx={{ backgroundColor: "#ffcc00", borderBottomWidth: 2 }}
              ></Divider>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              startIcon={<ArrowBack />}
              style={{
                backgroundColor: "#ffcc00",
                borderRadius: "05px",
                border: "1px solid black",
                color: "black",
              }}
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <Box height={10}></Box>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          sx={{ fontWeight: "bold", fontSize: "small" }}
        >
          <Grid item xs={3}>
            Initial Consultation Date
          </Grid>
          <Grid item xs={3}>
            Appointment Time
          </Grid>

          <Grid item xs={2}>
            Appointment Date
          </Grid>
          <Grid item xs={2}>
            Duration
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={3} sx={{ fontSize: "small" }}>
            {sessionDate}
          </Grid>
          <Grid item xs={3} sx={{ fontSize: "small" }}>
            {sesssionTime}
          </Grid>

          <Grid item xs={2} sx={{ fontSize: "small" }}>
            {sessionDate}
          </Grid>
          <Grid item xs={2} sx={{ fontSize: "small" }}>
            {duration} Min
          </Grid>
        </Grid>
      </Paper>
      <Box height={10}></Box>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        AIDA Reports
      </Typography>
      <Box height={10}></Box>

      <Grid container spacing={2}>
        {/* Session Summary */}
        {!sessionTranscriptisExpanded &&
          !diagnosisIsExpanded &&
          !treatmentIsExpanded && (
            <Grid item xs={12} md={sessionSummaryIsExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: sessionSummaryIsExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00",
                        },
                      }}
                    >
                      <Note sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      Session Summary
                    </Typography>

                    <div>
                      <CachedIcon
                        onClick={fetchSessionSummary}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <Box height={10}></Box>
                  <Typography
                    variant="body2"
                    component="div"
                    id="sessionSummaryDiv"
                    sx={{
                      flex: 1,
                      minHeight: "150px",
                      maxHeight: sessionSummaryIsExpanded ? "300px" : "50px",
                      overflowY: sessionSummaryIsExpanded ? "auto" : "hidden",
                      transition: "max-height 0.3s ease",
                      overflowX: "hidden",
                    }}
                  >
                    {sessionSummaryListData &&
                      sessionSummaryType?.trim() !== "" &&
                      (sessionSummaryType === "Non_Mental" ? (
                        <>
                          <span
                            className="session_report_title"
                            style={sessionReportTitle}
                          >
                            SOAP Note Summary
                          </span>
                          <ul>
                            <li style={{ fontWeight: "bold" }}>Subjective:</li>
                            <SubFormatArray
                              subIndex={"sex"}
                              keyName="Sex"
                              value={sessionSummaryGeneralData?.["sex"] || []}
                            />
                            <SubFormatArray
                              subIndex={"age"}
                              keyName="Age"
                              value={sessionSummaryGeneralData?.["age"] || []}
                            />
                            <SubFormatArray
                              subIndex={"chief_complaint"}
                              keyName="Chief Complaint (CC)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "chief_complaint"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"history_of_present_illness"}
                              keyName="History of Present Illness (HPI)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "history_of_present_illness"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"change_since_last_vist"}
                              keyName="Changes Since Last Visit"
                              value={
                                sessionSummaryGeneralData?.[
                                  "interval_history"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"history"}
                              keyName="History"
                              value={" "}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"psychiatric_history"}
                              keyName="Psychiatric History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_history"
                                ] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"cognitive_history"}
                              keyName="Cognitive History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cognitive_history"
                                ] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"childhood_trauma"}
                              keyName="Childhood Trauma"
                              value={
                                sessionSummaryGeneralData?.["child_trauma"] ||
                                []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"social_history"}
                              keyName="Social History"
                              value={" "}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"home_and_environment"}
                              keyName={"Home and Environment"}
                              values={[
                                sessionSummaryGeneralData?.["relationship"],
                                sessionSummaryGeneralData?.["friends"],
                                sessionSummaryGeneralData?.["support_system"],
                                sessionSummaryGeneralData?.["stress_level"],
                                sessionSummaryGeneralData?.["trigger"] || [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"education"}
                              keyName={"Education"}
                              values={
                                sessionSummaryGeneralData?.["education"] || []
                              }
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"employment"}
                              keyName={"Employment"}
                              values={
                                sessionSummaryGeneralData?.["employment"] || []
                              }
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"eating_and_sleeping"}
                              keyName={"Eating and Sleep"}
                              values={[
                                sessionSummaryGeneralData?.[
                                  "diet_and_nutrition"
                                ],
                                sessionSummaryGeneralData?.["sleep_pattern"] ||
                                  [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"activities"}
                              keyName={"Activities"}
                              values={[
                                sessionSummaryGeneralData?.[
                                  "physical_activity"
                                ],
                                sessionSummaryGeneralData?.["energy_level"],
                                sessionSummaryGeneralData?.[
                                  "hobbies_and_interests"
                                ] || [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"drugs"}
                              keyName={"Drugs"}
                              values={[
                                sessionSummarySudData?.["substance"],
                                sessionSummarySudData?.["secondary_substances"],
                                sessionSummarySudData?.["substance_start"],
                                sessionSummarySudData?.["frequency"],
                                sessionSummarySudData?.["administration"],
                                sessionSummaryGeneralData?.["overdose"] || [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"suicide"}
                              keyName={"Suicide"}
                              values={[
                                sessionSummaryGeneralData?.["suicide_attempt"],
                                sessionSummaryGeneralData?.[
                                  "suicide_ideation"
                                ] || [],
                              ]}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"medical_surgical_history"}
                              keyName="Medical/Surgical History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_medical_history"
                                ] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"medical_family_history"}
                              keyName="Medical Family History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "family_history_medical"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"review_of_system"}
                              keyName="Review of Systems (ROS)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "review_of_systems"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"current_medications"}
                              keyName="Current Medications"
                              value={
                                sessionSummaryGeneralData?.[
                                  "medication_review"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"allergies"}
                              keyName="Allergies"
                              value={
                                sessionSummaryGeneralData?.["allergies"] || []
                              }
                            />
                            <li style={sessionReportSubtitle}>Objective:</li>
                            <SubFormatArray
                              subIndex={"vital_signs"}
                              keyName="Vital Signs, Physical Exam, and Mental Status Examination"
                              value={
                                sessionSummaryGeneralData?.[
                                  "physical_examination"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"psychiatric"}
                              keyName="Psychiatric, Psychological, or Cognitive Tests"
                              value={
                                sessionSummaryGeneralData?.["framework"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"laboratory_tests"}
                              keyName="Laboratory Tests"
                              value={
                                sessionSummaryGeneralData?.["allergies"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"medical_problems"}
                              keyName={"Medical Problems"}
                              value={[
                                sessionSummaryGeneralData?.["liver_problem"],
                                sessionSummaryGeneralData?.["heart_problem"],
                                sessionSummaryGeneralData?.["thyroid_problem"],
                                sessionSummaryGeneralData?.["adrenal_problem"],
                                sessionSummaryGeneralData?.[
                                  "neurological_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "inflammatory_autoimmune_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "hormonal_imbalances"
                                ],
                                sessionSummaryGeneralData?.[
                                  "gastrointestinal_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ],
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ],
                                sessionSummaryGeneralData?.[
                                  "vitamin_deficiency"
                                ] || [],
                              ]}
                            />
                            <li style={sessionReportSubtitle}>Assesment:</li>
                            <SubFormatArray
                              subIndex={"primary_diagnosis"}
                              keyName="Primary Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["diagnosis"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"diffential_diagnosis"}
                              keyName="Differential Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["assessment"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"risk_factor"}
                              keyName="Risk Factor Assessment"
                              value={
                                sessionSummaryGeneralData?.[
                                  "risk_factor_assessment"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"safety_and_legal"}
                              keyName="Safety and Legal Concerns"
                              value={" "}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"access_to_firearms"}
                              keyName="Access to Firearms"
                              value={
                                sessionSummaryGeneralData?.["firearm"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"unsafe_environment"}
                              keyName="Unsafe Living Environment"
                              value={
                                sessionSummaryGeneralData?.["safety"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"history_of_violence"}
                              keyName="History of Violence"
                              value={
                                sessionSummaryGeneralData?.["violence"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"legal_issues"}
                              keyName="Legal Issues"
                              value={
                                sessionSummaryGeneralData?.["legal_problem"] ||
                                []
                              }
                            />
                            <li style={sessionReportSubtitle}>Plan:</li>
                            <SubFormatArray
                              subIndex={"management_plan"}
                              keyName="Management Plan"
                              value={sessionSummaryGeneralData?.["plan"] || []}
                            />
                            <SubFormatArray
                              subIndex={"laboratory"}
                              keyName="Laboratory"
                              value={
                                sessionSummaryGeneralData?.["lab_work"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"special_considerations"}
                              keyName="Special Considerations"
                              value={" "}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"insurance"}
                              keyName="Insurance"
                              value={
                                sessionSummaryGeneralData?.["insurance"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"pregnancy"}
                              keyName="Pregnancy"
                              value={
                                sessionSummaryGeneralData?.["pregnancy"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportTitle}
                          >
                            Detailed Summary
                          </span>
                          <br />
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            General Information
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"purpose_of_visit"}
                              keyName="Purpose of Visit"
                              value={
                                sessionSummaryGeneralData?.["visit_scenario"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"consultation_medium"}
                              keyName="Consultation Medium"
                              value={
                                sessionSummaryGeneralData?.["medium"] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Demographic Details:
                            </li>
                            <SubFormatArray
                              subIndex={"age"}
                              keyName="Age"
                              value={sessionSummaryGeneralData?.["age"] || []}
                            />
                            <SubFormatArray
                              subIndex={"gender"}
                              keyName="Gender"
                              value={sessionSummaryGeneralData?.["sex"] || []}
                            />

                            <SubFormatArray
                              subIndex={"education_level"}
                              keyName="Education Level"
                              value={
                                sessionSummaryGeneralData?.["education"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"employment_status"}
                              keyName="Employment Status"
                              value={
                                sessionSummaryGeneralData?.["employment"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Presenting Complaints
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"chiefcomplaint"}
                              keyName="Chief Complaint"
                              value={
                                sessionSummaryGeneralData?.[
                                  "chief_complaint"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"history_of_present_illness"}
                              keyName="History of Present Illness"
                              value={
                                sessionSummaryGeneralData?.[
                                  "history_of_present_illness"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"changes_since_last_visit"}
                              keyName="Changes Since Last Visit"
                              value={
                                sessionSummaryGeneralData?.[
                                  "interval_history"
                                ] || []
                              }
                            />
                          </ul>
                          {/* Common End** */}
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Medical and Physical Health History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"past_surgeries_and_hospitalizations"}
                              keyName="Past Surgeries and Hospitalizations"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_medical_history"
                                ] || []
                              }
                            />

                            <FormatArray
                              subIndex={"family_medical_history"}
                              keyName="Family Medical History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "family_medical_history"
                                ] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Medical Conditions:
                            </li>
                            <SubFormatArray
                              subIndex={"Liver Issues"}
                              keyName="Liver Issues"
                              value={
                                sessionSummaryGeneralData?.["liver_problem"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"support_systems"}
                              keyName="heart_issues"
                              value={
                                sessionSummaryGeneralData?.["heart_problem"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"thyroid_problems"}
                              keyName="Thyroid Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "thyroid_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"adrenal_problems"}
                              keyName="Adrenal Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "adrenal_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"neurological_conditions"}
                              keyName="Neurological Conditions"
                              value={
                                sessionSummaryGeneralData?.[
                                  "neurological_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"autoimmune_inflammatory_disorders"}
                              keyName="Autoimmune/Inflammatory Disorders"
                              value={
                                sessionSummaryGeneralData?.[
                                  "inflammatory_autoimmune_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"infections"}
                              keyName="Infections"
                              value={
                                sessionSummaryGeneralData?.["infection"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"support_systems"}
                              keyName="hormonal_imbalances"
                              value={
                                sessionSummaryGeneralData?.[
                                  "hormonal_imbalnces"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"gastrointestinal_issues"}
                              keyName="Gastrointestinal Issues"
                              value={
                                sessionSummaryGeneralData?.[
                                  "gastrointestinal_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"diabetes_mellitus"}
                              keyName="Diabetes Mellitus"
                              value={
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"vitamin_deficiency"}
                              keyName="Vitamin Deficiency"
                              value={
                                sessionSummaryGeneralData?.[
                                  "vitamin_deficiency"
                                ] || []
                              }
                            />

                            <FormatArray
                              subIndex={"allergies"}
                              keyName="Allergies"
                              value={
                                sessionSummaryGeneralData?.["allergies"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"immunization_status"}
                              keyName="Immunization Status"
                              value={
                                sessionSummaryGeneralData?.[
                                  "immunization_status"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"medication"}
                              keyName="Medication"
                              value={
                                sessionSummaryGeneralData?.[
                                  "medication_review"
                                ] || []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Mental Health and Psychiatric History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"EmotionalWellBeing"}
                              keyName="Current Mental Health Status"
                              value={
                                sessionSummaryGeneralData?.["mental_health"]
                              }
                            />
                            <FormatArray
                              subIndex={"consultation_medium"}
                              keyName="Cognitive Functioning"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cognitive_functioning"
                                ] || []
                              }
                            />

                            <FormatArray
                              subIndex={"gender_identity_and_related_issues"}
                              keyName="Gender Identity and Related Issues"
                              value={
                                sessionSummaryGeneralData?.["gender_issue"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"previous_psychiatric_problems"}
                              keyName="Previous Psychiatric Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_history"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"childhood_trauma_history"}
                              keyName="Childhood Trauma History"
                              value={
                                sessionSummaryGeneralData?.["child_trauma"] ||
                                []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Substance Use History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"primary_substance"}
                              keyName="Primary Substance"
                              value={
                                sessionSummaryGeneralData?.["substance"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"secondary_substances"}
                              keyName="Secondary Substances"
                              value={
                                sessionSummaryGeneralData?.[
                                  "secondary_substances"
                                ] || []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Usage Patterns:
                            </li>
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Substance Use Onset"
                              value={
                                sessionSummaryGeneralData?.[
                                  "substance_start"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Frequency and Dosage"
                              value={
                                sessionSummaryGeneralData?.["frequency"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Method of Administration"
                              value={
                                sessionSummaryGeneralData?.["administration"] ||
                                []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Family and Social History
                          </span>
                          <ul>
                            <li style={{ fontWeight: "bold" }}>
                              Lifestyle Factors:
                            </li>
                            <SubFormatArray
                              subIndex={"friends_and_colleagues"}
                              keyName="Physical Activity"
                              value={
                                sessionSummaryGeneralData?.[
                                  "physical_activity"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"diet_and_nutrition"}
                              keyName="Diet and Nutrition"
                              value={
                                sessionSummaryGeneralData?.[
                                  "diet_and_nutrition"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"sleep_patterns"}
                              keyName="Sleep Patterns"
                              value={
                                sessionSummaryGeneralData?.["sleep_pattern"] ||
                                []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Social History:
                            </li>
                            <SubFormatArray
                              subIndex={"relationship_status"}
                              keyName="Relationship Status"
                              value={
                                sessionSummaryGeneralData?.["relationship"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"cognitive_history"}
                              keyName="Friends"
                              value={
                                sessionSummaryGeneralData?.["friends"] || []
                              }
                            />

                            <SubFormatArray
                              subIndex={"past_coping_mechanisms"}
                              keyName="Support System"
                              value={
                                sessionSummaryGeneralData?.["support_system"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"gender_identity_and_related_issues"}
                              keyName="Home and Environment Safety"
                              value={
                                sessionSummaryGeneralData?.["safety"] || []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Clinical Examination
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"physical_examination"}
                              keyName="Physical Examination"
                              value={
                                sessionSummaryGeneralData?.[
                                  "physical_examination"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"review_of_Systems_(ROS)"}
                              keyName="Review of Systems (ROS)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "review_of_systems"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"growth_and_development"}
                              keyName="Growth and Development (for Pediatrics Visits)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "growth_and_development"
                                ] || []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Diagnostic Findings
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"physical_examination"}
                              keyName="Health Maintenance (Preventive Care Review)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "health_maintenance_review"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"review_of_Systems_(ROS)"}
                              keyName="Lab Results"
                              value={
                                sessionSummaryGeneralData?.[
                                  "recent_lab_work"
                                ] || []
                              }
                            />
                          </ul>

                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Clinical Evaluation
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={
                                "problem_list_and_differential_diagnosis"
                              }
                              keyName="Problem List and Differential Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["assessment"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"risk_factor_assessment"}
                              keyName="Risk Factor Assessment"
                              value={
                                sessionSummaryGeneralData?.[
                                  "risk_factor_assessment"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"functional_status"}
                              keyName="Functional Status (Impact on Daily Life)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "functional_status"
                                ] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Treatment and Follow-Up
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"diagnosis"}
                              keyName="Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["diagnosis"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"management_plan"}
                              keyName="Management Plan"
                              value={sessionSummaryGeneralData?.["plan"] || []}
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Special Considerations:
                            </li>
                            <SubFormatArray
                              subIndex={"pregnancy_status"}
                              keyName="Pregnancy Status"
                              value={
                                sessionSummaryGeneralData?.["pregnancy"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"comorbid_conditions"}
                              keyName="Comorbid Conditions"
                              value={
                                sessionSummaryGeneralData?.[
                                  "secondary_diagnosis"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"insurance_coverage"}
                              keyName="Insurance Coverage"
                              value={
                                sessionSummaryGeneralData?.["insurance"] || []
                              }
                            />
                          </ul>
                        </>
                      ) : (
                        <>
                          <span
                            className="session_report_title"
                            style={sessionReportTitle}
                          >
                            SOAP Note Summary
                          </span>
                          <ul>
                            <li style={sessionReportSubtitle}>Subjective:</li>
                            <SubFormatArray
                              subIndex={"sex"}
                              keyName="Sex"
                              value={sessionSummaryGeneralData?.["sex"] || []}
                            />
                            <SubFormatArray
                              subIndex={"age"}
                              keyName="Age"
                              value={sessionSummaryGeneralData?.["age"] || []}
                            />
                            <SubFormatArray
                              subIndex={"chief_complaint"}
                              keyName="Chief Complaint (CC)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "chief_complaint"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"history_of_present_illness"}
                              keyName="History of Present Illness (HPI)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "history_of_present_illness"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"change_since_last_vist"}
                              keyName="Changes Since Last Visit"
                              value={
                                sessionSummaryGeneralData?.[
                                  "interval_history"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"history"}
                              keyName="History"
                              value={" "}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"psychiatric_history"}
                              keyName="Psychiatric History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_history"
                                ] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"cognitive_history"}
                              keyName="Cognitive History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cognitive_history"
                                ] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"childhood_trauma"}
                              keyName="Childhood Trauma"
                              value={
                                sessionSummaryGeneralData?.["child_trauma"] ||
                                []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"social_history"}
                              keyName="Social History"
                              value={" "}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"home_and_environment"}
                              keyName={"Home and Environment"}
                              values={[
                                sessionSummaryGeneralData?.["relationship"],
                                sessionSummaryGeneralData?.["friends"],
                                sessionSummaryGeneralData?.["support_system"],
                                sessionSummaryGeneralData?.["stress_level"],
                                sessionSummaryGeneralData?.["trigger"] || [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"education"}
                              keyName={"Education"}
                              values={
                                sessionSummaryGeneralData?.["education"] || []
                              }
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"employment"}
                              keyName={"Employment"}
                              values={
                                sessionSummaryGeneralData?.["employment"] || []
                              }
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"eating_and_sleeping"}
                              keyName={"Eating and Sleep"}
                              values={[
                                sessionSummaryGeneralData?.[
                                  "diet_and_nutrition"
                                ],
                                sessionSummaryGeneralData?.["sleep_pattern"] ||
                                  [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"activities"}
                              keyName={"Activities"}
                              values={[
                                sessionSummaryGeneralData?.[
                                  "physical_activity"
                                ],
                                sessionSummaryGeneralData?.["energy_level"],
                                sessionSummaryGeneralData?.[
                                  "hobbies_and_interests"
                                ] || [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"drugs"}
                              keyName={"Drugs"}
                              values={[
                                sessionSummarySudData?.["substance"],
                                sessionSummarySudData?.["secondary_substances"],
                                sessionSummarySudData?.["substance_start"],
                                sessionSummarySudData?.["frequency"],
                                sessionSummarySudData?.["administration"],
                                sessionSummaryGeneralData?.["overdose"] || [],
                              ]}
                            />
                            <GrandChildOfSubFormatArray
                              subIndex={"suicide"}
                              keyName={"Suicide"}
                              values={[
                                sessionSummaryGeneralData?.["suicide_attempt"],
                                sessionSummaryGeneralData?.[
                                  "suicide_ideation"
                                ] || [],
                              ]}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"medical_surgical_history"}
                              keyName="Medical/Surgical History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_medical_history"
                                ] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"medical_family_history"}
                              keyName="Medical Family History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "family_history_medical"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"review_of_system"}
                              keyName="Review of Systems (ROS)"
                              value={
                                sessionSummaryGeneralData?.[
                                  "review_of_systems"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"current_medications"}
                              keyName="Current Medications"
                              value={
                                sessionSummaryGeneralData?.[
                                  "medication_review"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"allergies"}
                              keyName="Allergies"
                              value={
                                sessionSummaryGeneralData?.["allergies"] || []
                              }
                            />
                            <li style={sessionReportSubtitle}>Objective:</li>
                            <SubFormatArray
                              subIndex={"vital_signs"}
                              keyName="Vital Signs, Physical Exam, and Mental Status Examination"
                              value={
                                sessionSummaryGeneralData?.[
                                  "physical_examination"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"psychiatric"}
                              keyName="Psychiatric, Psychological, or Cognitive Tests"
                              value={
                                sessionSummaryGeneralData?.["framework"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"laboratory_tests"}
                              keyName="Laboratory Tests"
                              value={
                                sessionSummaryGeneralData?.["allergies"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"medical_problems"}
                              keyName={"Medical Problems"}
                              value={[
                                sessionSummaryGeneralData?.["liver_problem"],
                                sessionSummaryGeneralData?.["heart_problem"],
                                sessionSummaryGeneralData?.["thyroid_problem"],
                                sessionSummaryGeneralData?.["adrenal_problem"],
                                sessionSummaryGeneralData?.[
                                  "neurological_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "inflammatory_autoimmune_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "hormonal_imbalances"
                                ],
                                sessionSummaryGeneralData?.[
                                  "gastrointestinal_problem"
                                ],
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ],
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ],
                                sessionSummaryGeneralData?.[
                                  "vitamin_deficiency"
                                ] || [],
                              ]}
                            />
                            <li style={sessionReportSubtitle}>Assesment:</li>
                            <SubFormatArray
                              subIndex={"primary_diagnosis"}
                              keyName="Primary Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["diagnosis"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"diffential_diagnosis"}
                              keyName="Differential Diagnosis"
                              value={
                                sessionSummaryGeneralData?.["assessment"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"risk_factor"}
                              keyName="Risk Factor Assessment"
                              value={
                                sessionSummaryGeneralData?.[
                                  "risk_factor_assessment"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"safety_and_legal"}
                              keyName="Safety and Legal Concerns"
                              value={" "}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"access_to_firearms"}
                              keyName="Access to Firearms"
                              value={
                                sessionSummaryGeneralData?.["firearm"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"unsafe_environment"}
                              keyName="Unsafe Living Environment"
                              value={
                                sessionSummaryGeneralData?.["safety"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"history_of_violence"}
                              keyName="History of Violence"
                              value={
                                sessionSummaryGeneralData?.["violence"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"legal_issues"}
                              keyName="Legal Issues"
                              value={
                                sessionSummaryGeneralData?.["legal_problem"] ||
                                []
                              }
                            />
                            <li style={sessionReportSubtitle}>Plan:</li>
                            <SubFormatArray
                              subIndex={"management_plan"}
                              keyName="Management Plan"
                              value={sessionSummaryGeneralData?.["plan"] || []}
                            />
                            <SubFormatArray
                              subIndex={"laboratory"}
                              keyName="Laboratory"
                              value={
                                sessionSummaryGeneralData?.["lab_work"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"special_considerations"}
                              keyName="Special Considerations"
                              value={" "}
                            />
                            <ChildOfSubFormatArray
                              subIndex={"insurance"}
                              keyName="Insurance"
                              value={
                                sessionSummaryGeneralData?.["insurance"] || []
                              }
                            />
                            <ChildOfSubFormatArray
                              subIndex={"pregnancy"}
                              keyName="Pregnancy"
                              value={
                                sessionSummaryGeneralData?.["pregnancy"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportTitle}
                          >
                            Detailed Summary
                          </span>
                          <br />
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            General Information
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"purpose_of_visit"}
                              keyName="Purpose of Visit"
                              value={
                                sessionSummaryGeneralData?.["visit_scenario"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"consultation_medium"}
                              keyName="Consultation Medium"
                              value={
                                sessionSummaryGeneralData?.["medium"] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Demographic Details:
                            </li>
                            <SubFormatArray
                              subIndex={"age"}
                              keyName="Age"
                              value={sessionSummaryGeneralData?.["age"] || []}
                            />
                            <SubFormatArray
                              subIndex={"gender"}
                              keyName="Gender"
                              value={sessionSummaryGeneralData?.["sex"] || []}
                            />

                            <SubFormatArray
                              subIndex={"education_level"}
                              keyName="Education Level"
                              value={
                                sessionSummaryGeneralData?.["education"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"employment_status"}
                              keyName="Employment Status"
                              value={
                                sessionSummaryGeneralData?.["employment"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Presenting Complaints
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"chiefcomplaint"}
                              keyName="Chief Complaint"
                              value={
                                sessionSummaryGeneralData?.[
                                  "chief_complaint"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"history_of_present_illness"}
                              keyName="History of Present Illness"
                              value={
                                sessionSummaryGeneralData?.[
                                  "history_of_present_illness"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"changes_since_last_visit"}
                              keyName="Changes Since Last Visit"
                              value={
                                sessionSummaryGeneralData?.[
                                  "interval_history"
                                ] || []
                              }
                            />
                          </ul>
                          {/* Common End** */}
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Mental Health and Psychiatric History
                          </span>
                          <ul>
                            <li style={{ fontWeight: "bold" }}>
                              Current Mental Health Status
                            </li>
                            <SubFormatArray
                              subIndex={"EmotionalWellBeing"}
                              keyName="Emotional Well-Being"
                              value={
                                sessionSummaryGeneralData?.["mental_health"]
                              }
                            />
                            <SubFormatArray
                              subIndex={"consultation_medium"}
                              keyName="Cognitive Functioning"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cognitive_functioning"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"consultation_medium"}
                              keyName="Coping Strategies"
                              value={
                                sessionSummarySudData?.["coping_strategy"] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Past Psychiatric History
                            </li>
                            <SubFormatArray
                              subIndex={"previous_psychiatric_problems"}
                              keyName="Previous Psychiatric Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_history"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"cognitive_history"}
                              keyName="Cognitive History"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cognitive_history"
                                ] || []
                              }
                            />

                            <SubFormatArray
                              subIndex={"past_coping_mechanisms"}
                              keyName="Past Coping Mechanisms"
                              value={
                                sessionSummarySudData?.[
                                  "past_coping_strategy"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"gender_identity_and_related_issues"}
                              keyName="Gender Identity and Related Issues"
                              value={
                                sessionSummaryGeneralData?.["gender_issue"] ||
                                []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Suicide Risk Assessment
                            </li>
                            <SubFormatArray
                              subIndex={"suicide_attempts"}
                              keyName="Suicide Attempts"
                              value={
                                sessionSummaryGeneralData?.[
                                  "suicide_attempt"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"suicide_attempts"}
                              keyName="Suicide Ideation or Plans"
                              value={
                                sessionSummaryGeneralData?.[
                                  "suicide_ideation"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"consultation_medium"}
                              keyName="Psychiatric Medication Review"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_psychiatric_medication"
                                ] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Substance Use History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"primary_substance"}
                              keyName="Primary Substance"
                              value={sessionSummarySudData?.["substance"] || []}
                            />
                            <FormatArray
                              subIndex={"secondary_substances"}
                              keyName="Secondary Substances"
                              value={
                                sessionSummarySudData?.[
                                  "secondary_substances"
                                ] || []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Usage Patterns:
                            </li>
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Substance Use Onset"
                              value={
                                sessionSummarySudData?.["substance_start"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Frequency and Dosage"
                              value={sessionSummarySudData?.["frequency"] || []}
                            />
                            <SubFormatArray
                              subIndex={"substance_use_onset"}
                              keyName="Method of Administration"
                              value={
                                sessionSummarySudData?.["administration"] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Substance-Related Risks
                            </li>
                            <SubFormatArray
                              subIndex={"history_of_r_elapse"}
                              keyName="History of Relapse"
                              value={sessionSummarySudData?.["relapse"] || []}
                            />
                            <SubFormatArray
                              subIndex={"history_of_overdose"}
                              keyName="History of Overdose"
                              value={sessionSummarySudData?.["overdose"] || []}
                            />
                            <SubFormatArray
                              subIndex={"withdrawal_symptoms"}
                              keyName="Withdrawal Symptoms"
                              value={
                                sessionSummarySudData?.[
                                  "withdrawal_symptoms"
                                ] || []
                              }
                            />

                            <li style={{ fontWeight: "bold" }}>
                              Motivation and Readiness for Change:
                            </li>
                            <SubFormatArray
                              subIndex={"motivation_to_quit"}
                              keyName="Motivation to Quit"
                              value={
                                sessionSummarySudData?.["motivation"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"readiness_to_engage_in_treatment"}
                              keyName="Readiness to Engage in Treatment"
                              value={sessionSummarySudData?.["readiness"] || []}
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Lifestyle and Daily Functioning
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"sleep_patterns"}
                              keyName="Sleep Patterns"
                              value={
                                sessionSummaryGeneralData?.["sleep_pattern"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"Physical_activity"}
                              keyName="Physical Activity"
                              value={
                                sessionSummaryGeneralData?.[
                                  "physical_activity"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"energy_Levels"}
                              keyName="Energy Levels"
                              value={
                                sessionSummaryGeneralData?.["energy_level"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"daily_routine"}
                              keyName="Daily Routine"
                              value={
                                sessionSummaryGeneralData?.["daily_routine"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"diet_and_nutrition"}
                              keyName="Diet and Nutrition"
                              value={
                                sessionSummaryGeneralData?.[
                                  "diet_and_nutrition"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"stress_level"}
                              keyName="Stress Level"
                              value={
                                sessionSummaryGeneralData?.["stress_level"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={
                                "triggers_for_mental_health_episodes_or_substance_use"
                              }
                              keyName="Triggers for Mental Health Episodes or Substance Use"
                              value={
                                sessionSummaryGeneralData?.["trigger"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"hobbies_and_interests"}
                              keyName="Hobbies and Interests"
                              value={
                                sessionSummaryGeneralData?.[
                                  "hobbies_and_interests"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"self_perception_and_confidence"}
                              keyName="Self-Perception and Confidence"
                              value={
                                sessionSummaryGeneralData?.[
                                  "self_perception_and_confidence"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"selfcare_habits"}
                              keyName="Self-Care Habits"
                              value={
                                sessionSummaryGeneralData?.["self_care"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Family and Social History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"history_of_childhood_trauma"}
                              keyName="History of Childhood Trauma"
                              value={
                                sessionSummaryGeneralData?.["child_trauma"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"family_relationships"}
                              keyName="Family Relationships"
                              value={
                                sessionSummaryGeneralData?.["relationship"] ||
                                []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Social Relationships:
                            </li>
                            <SubFormatArray
                              subIndex={"friends_and_colleagues"}
                              keyName="Friends and Colleagues"
                              value={
                                sessionSummaryGeneralData?.["friends"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"support_systems"}
                              keyName="Support Systems"
                              value={
                                sessionSummaryGeneralData?.["support_system"] ||
                                []
                              }
                            />
                            <FormatArray
                              subIndex={"cultural_and_religious_beliefs"}
                              keyName="Cultural and Religious Beliefs"
                              value={
                                sessionSummaryGeneralData?.[
                                  "cultural_belief"
                                ] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Safety and Legal Concerns
                          </span>
                          <ul>
                            <li style={{ fontWeight: "bold" }}>
                              Safety Risks:
                            </li>
                            <SubFormatArray
                              subIndex={"access_to_firearms"}
                              keyName="Access To Firearms"
                              value={
                                sessionSummaryGeneralData?.["firearm"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"unsafe_living_environment"}
                              keyName="Unsafe Living Environment"
                              value={
                                sessionSummaryGeneralData?.["safety"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"history_of_violence"}
                              keyName="History of Violence"
                              value={
                                sessionSummaryGeneralData?.["violence"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"legal_issues_(ongoing or past)"}
                              keyName="Legal Issues (ongoing or past)"
                              value={
                                sessionSummaryGeneralData?.["legal_problem"] ||
                                []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Medical and Physical Health History
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"chronic_pain_history"}
                              keyName="Chronic Pain History"
                              value={
                                sessionSummaryGeneralData?.["chronic_pain"] ||
                                []
                              }
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Medical Conditions:
                            </li>
                            <SubFormatArray
                              subIndex={"Liver Issues"}
                              keyName="Liver Issues"
                              value={
                                sessionSummaryGeneralData?.["liver_problem"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"support_systems"}
                              keyName="heart_issues"
                              value={
                                sessionSummaryGeneralData?.["heart_problem"] ||
                                []
                              }
                            />
                            <SubFormatArray
                              subIndex={"thyroid_problems"}
                              keyName="Thyroid Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "thyroid_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"adrenal_problems"}
                              keyName="Adrenal Problems"
                              value={
                                sessionSummaryGeneralData?.[
                                  "adrenal_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"neurological_conditions"}
                              keyName="Neurological Conditions"
                              value={
                                sessionSummaryGeneralData?.[
                                  "neurological_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"autoimmune_inflammatory_disorders"}
                              keyName="Autoimmune/Inflammatory Disorders"
                              value={
                                sessionSummaryGeneralData?.[
                                  "inflammatory_autoimmune_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"infections"}
                              keyName="Infections"
                              value={
                                sessionSummaryGeneralData?.["infection"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"support_systems"}
                              keyName="hormonal_imbalances"
                              value={
                                sessionSummaryGeneralData?.[
                                  "hormonal_imbalnces"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"gastrointestinal_issues"}
                              keyName="Gastrointestinal Issues"
                              value={
                                sessionSummaryGeneralData?.[
                                  "gastrointestinal_problem"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"diabetes_mellitus"}
                              keyName="Diabetes Mellitus"
                              value={
                                sessionSummaryGeneralData?.[
                                  "diabetes_mellitus"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"vitamin_deficiency"}
                              keyName="Vitamin Deficiency"
                              value={
                                sessionSummaryGeneralData?.[
                                  "vitamin_deficiency"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"allergies"}
                              keyName="Allergies"
                              value={
                                sessionSummaryGeneralData?.["allergies"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"past_surgeries_and_hospitalizations"}
                              keyName="Past Surgeries and Hospitalizations"
                              value={
                                sessionSummaryGeneralData?.[
                                  "past_medical_history"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"medication_review"}
                              keyName="Medication Review"
                              value={
                                sessionSummaryGeneralData?.[
                                  "medication_review"
                                ] || []
                              }
                            />
                            <FormatArray
                              subIndex={"recent_lab_results"}
                              keyName="Recent Lab Results"
                              value={
                                sessionSummaryGeneralData?.[
                                  "recent_lab_work"
                                ] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Screening
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"framework"}
                              keyName="Psychiatric, Psychological, or Cognitive Tests"
                              value={
                                sessionSummaryGeneralData?.["framework"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            Treatment and Follow-Up
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"Diagnosis"}
                              keyName="diagnosis"
                              value={
                                sessionSummaryGeneralData?.["diagnosis"] || []
                              }
                            />
                            <FormatArray
                              subIndex={"management_plan"}
                              keyName="Management Plan"
                              value={sessionSummaryGeneralData?.["plan"] || []}
                            />
                            <li style={{ fontWeight: "bold" }}>
                              Special Considerations:
                            </li>
                            <SubFormatArray
                              subIndex={"pregnancy_status"}
                              keyName="Pregnancy Status"
                              value={
                                sessionSummaryGeneralData?.["pregnancy"] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"comorbid_conditions"}
                              keyName="Comorbid Conditions"
                              value={
                                sessionSummaryGeneralData?.[
                                  "secondary_diagnosis"
                                ] || []
                              }
                            />
                            <SubFormatArray
                              subIndex={"insurance_coverage"}
                              keyName="Insurance Coverage"
                              value={
                                sessionSummaryGeneralData?.["insurance"] || []
                              }
                            />
                          </ul>
                          <span
                            className="session_report_title"
                            style={sessionReportSubtitle}
                          >
                            AIDA’s Touch
                          </span>
                          <ul>
                            <FormatArray
                              subIndex={"language_and_Word_choice"}
                              keyName="Language and Word Choice"
                              value={
                                sessionSummaryGeneralData?.["vocabulary"] || []
                              }
                            />
                          </ul>
                        </>
                      ))}

                    {sessionSummaryListData &&
                      sessionSummaryType?.trim() === "" && (
                        <Typography
                          style={{ textAlign: "center", fontSize: "small" }}
                        >
                          Your reports are being generated. They will
                          automatically appear on screen once ready.
                        </Typography>
                      )}

                    {sessionSummaryLoading && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 3 }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Typography>
                </Box>

                {!sessionSummaryIsExpanded &&
                  !sessionSummaryLoading &&
                  sessionSummaryList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("session_summary")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {sessionSummaryIsExpanded ? "View Less" : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() => downloadTxtFile("session_summary")}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {sessionSummaryIsExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}

        {/* AIDA Diagnosis Recommendation */}
        {!sessionTranscriptisExpanded &&
          !sessionSummaryIsExpanded &&
          !treatmentIsExpanded && (
            <Grid item xs={12} md={diagnosisIsExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: diagnosisIsExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px", // Adjust the border radius as needed
                      display: "flex",
                      gap: "10px", // Adjust the gap between icon and text as needed
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00",
                        },
                      }}
                    >
                      <StickyNote2 sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      AIDA Diagnosis Recommendation
                    </Typography>

                    <div>
                      <CachedIcon
                        onClick={fetchSessionAIDADignosis}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <Box height={10}></Box>
                  <Typography
                    variant="body2"
                    component="div"
                    id="sessionDignosisDiv"
                    sx={{
                      flex: 1, // Makes it fill the available space
                      minHeight: "150px", // Set minimum height for the transcript
                      maxHeight: diagnosisIsExpanded ? "300px" : "150px", // Expand/Collapse max height
                      overflowY: diagnosisIsExpanded ? "auto" : "hidden",
                      transition: "max-height 0.3s ease", // Smooth transition when expanding/collapsing
                      overflowX: "hidden",
                    }}
                  >
                    {Boolean(sessionSummaryListData.length) &&
                     (
                        <>
                          <span style={sessionReportTitle}>
                            Diagnosis Summary
                          </span>
                          <ul>
                            <li key="Principal Diagnosis">
                              <strong>Principal Diagnosis:</strong>{" "}
                              {diagnoDetailsSummary.length > 0
                                ? diagnoDetailsSummary.map((item, index) => (
                                    <React.Fragment key={index}>
                                      {index === 0 ? (
                                        item.disorder.length &&
                                        item.severity.length ? (
                                          `${item.disorder},${item.severity}`
                                        ) : (
                                          " "
                                        )
                                      ) : (
                                        <span
                                          style={{
                                            display: "block",
                                            paddingLeft: "2em",
                                          }}
                                        >
                                          {item.disorder.length &&
                                          item.severity.length
                                            ? `${item.disorder},${item.severity}`
                                            : " "}
                                        </span>
                                      )}
                                    </React.Fragment>
                                  ))
                                : " "}
                            </li>
                            <FormatArray
                              subIndex={"code"}
                              keyName="ICD-10 CM code"
                              value={diagnoDetailInfo}
                            />
                          </ul>
                          <span style={sessionReportTitle}>
                            Diagnosis Details
                          </span>
                          {sessionSummaryType === "Mental" && (
                            <>
                              {((sessionSummaryListData.length &&
                                availableDignosisTypeList.includes("mdd")) ||
                                String(
                                  sessionSummaryListData.find(
                                    ([key]) => key === "MDD"
                                  )?.[1] ?? "No"
                                ) === "Yes") && (
                                <>
                                  <h3 style={{ marginLeft: "20px" }}>
                                    Major Depressive Disorder
                                  </h3>
                                  <ul>
                                    <li key="Principal Diagnosis">
                                      <strong>Principal Diagnosis:</strong>{" "}
                                      {diagnosisList.find((item) => item.mdd)
                                        ?.mdd?.diagnosis
                                        ? diagnosisList
                                            .find((item) => item.mdd)
                                            ?.mdd?.diagnosis.map(
                                              (
                                                item: {
                                                  disorder: string | any[];
                                                  severity: string | any[];
                                                },
                                                index:
                                                  | React.Key
                                                  | null
                                                  | undefined
                                              ) => (
                                                <React.Fragment key={index}>
                                                  {index === 0 ? (
                                                    item.disorder.length ? (
                                                      removeLastCommaAndWord(
                                                        item.disorder
                                                      )
                                                    ) : (
                                                      "N-A"
                                                    )
                                                  ) : (
                                                    <span
                                                      style={{
                                                        display: "block",
                                                        paddingLeft: "2em",
                                                      }}
                                                    >
                                                      {item.disorder.length
                                                        ? removeLastCommaAndWord(
                                                            item.disorder
                                                          )
                                                        : " "}
                                                    </span>
                                                  )}
                                                </React.Fragment>
                                              )
                                            )
                                        : " "}
                                    </li>
                                    <FormatArray
                                      subIndex={"code"}
                                      keyName="ICD-10 CM code"
                                      value={(() => {
                                        const mddData =
                                          diagnosisList.find((item) => item.mdd)
                                            ?.mdd?.diagnosis || [];
                                        if (mddData.length > 0) {
                                          return mddData.map(
                                            (
                                              item: { code: string | any[] },
                                              index:
                                                | React.Key
                                                | null
                                                | undefined
                                            ) => (
                                              <React.Fragment key={index}>
                                                {index === 0 ? (
                                                  item.code?.length ? (
                                                    `${item.code}`
                                                  ) : (
                                                    " "
                                                  )
                                                ) : (
                                                  <span
                                                    style={{
                                                      display: "block",
                                                      paddingLeft: "2em",
                                                    }}
                                                  >
                                                    {item.code?.length
                                                      ? `${item.code}`
                                                      : " "}
                                                  </span>
                                                )}
                                              </React.Fragment>
                                            )
                                          );
                                        }
                                        return " ";
                                      })()}
                                    />

                                    <FormatArray
                                      subIndex={"episode_type"}
                                      keyName="Episode Type"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["15_mdd_episode_type"] || []
                                      }
                                    />
                                    <FormatArray
                                      subIndex={"serverity"}
                                      keyName="Severity"
                                      value={(() => {
                                        const mudData =
                                          diagnosisList.find((item) => item.mdd)
                                            ?.mdd?.diagnosis || [];
                                        if (mudData.length > 0) {
                                          return mudData.map(
                                            (
                                              item: {
                                                severity: string | any[];
                                              },
                                              index:
                                                | React.Key
                                                | null
                                                | undefined
                                            ) => (
                                              <React.Fragment key={index}>
                                                {index === 0 ? (
                                                  item.severity?.length ? (
                                                    `${item.severity}`
                                                  ) : (
                                                    " "
                                                  )
                                                ) : (
                                                  <span
                                                    style={{
                                                      display: "block",
                                                      paddingLeft: "2em",
                                                    }}
                                                  >
                                                    {item.severity?.length
                                                      ? `${item.severity}`
                                                      : " "}
                                                  </span>
                                                )}
                                              </React.Fragment>
                                            )
                                          );
                                        }
                                        return " ";
                                      })()}
                                    />
                                    <FormatArray
                                      subIndex={"serverity_partial"}
                                      keyName="Remission Status Partial"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["17a_mdd_remission_partial"] ||
                                        []
                                      }
                                    />
                                    <FormatArray
                                      subIndex={"serverity_full"}
                                      keyName="Remission Status Full"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["17b_mdd_remission_full"] || []
                                      }
                                    />

                                    <li style={{ fontWeight: "bold" }}>
                                      Specific Features:
                                    </li>
                                    <SubFormatArray
                                      subIndex={"znxious_distress"}
                                      keyName="Anxious Distress"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["18_mdd_distress"] || []
                                      }
                                    />
                                    <SubFormatArray
                                      subIndex={"mixed_features"}
                                      keyName="Mixed Features"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["19_mdd_mixed"] || []
                                      }
                                    />

                                    <SubFormatArray
                                      subIndex={"melancholic_features"}
                                      keyName="Melancholic Features"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["20_mdd_melancholic"] || []
                                      }
                                    />
                                    <SubFormatArray
                                      subIndex={"atypical_features"}
                                      keyName="Atypical Features"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["21_mdd_atypical"] || []
                                      }
                                    />
                                    <SubFormatArray
                                      subIndex={"psychotic_features"}
                                      keyName="Psychotic Features"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["22_mdd_psychotic"] || []
                                      }
                                    />
                                    <SubFormatArray
                                      subIndex={"catatonia"}
                                      keyName="Catatonia"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["23_mdd_catatonia"] || []
                                      }
                                    />
                                    <SubFormatArray
                                      subIndex={"peripartum_onset"}
                                      keyName="Peripartum Onset"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["24_mdd_peripartum"] || []
                                      }
                                    />
                                    <SubFormatArray
                                      subIndex={"seasonal_pattern"}
                                      keyName="Seasonal Pattern"
                                      value={
                                        sessionSummaryListData.find(
                                          ([key]) => key === "summary_mdd"
                                        )?.[1]?.["25_mdd_seasonal"] || []
                                      }
                                    />
                                    <FormatArray
                                      subIndex={"diagnostic_frameworks"}
                                      keyName="Diagnostic Frameworks"
                                      value={
                                        "ICD-10 & DSM-5 Depressive disorders"
                                      }
                                    />
                                  </ul>
                                </>
                              )}
                              {((sessionSummaryListData.length &&
                                availableDignosisTypeList.length &&
                                availableDignosisTypeList.includes("gad")) ||
                                String(
                                  sessionSummaryListData.find(
                                    ([key]) => key === "GAD"
                                  )?.[1] ?? "No"
                                ) === "Yes") &&
                                 (
                                  <>
                                    <h3 style={{ marginLeft: "20px" }}>
                                      Generalized Anxiety Disorder
                                    </h3>
                                    <ul>
                                      <li key="Principal Diagnosis">
                                        <strong>Principal Diagnosis:</strong>{" "}
                                        {diagnosisList.find((item) => item.gad)
                                          ?.gad?.diagnosis
                                          ? diagnosisList
                                              .find((item) => item.gad)
                                              ?.gad?.diagnosis.map(
                                                (
                                                  item: {
                                                    disorder: string | any[];
                                                    severity: string | any[];
                                                  },
                                                  index:
                                                    | React.Key
                                                    | null
                                                    | undefined
                                                ) => (
                                                  <React.Fragment key={index}>
                                                    {index === 0 ? (
                                                      item.disorder.length ? (
                                                        removeLastCommaAndWord(
                                                          item.disorder
                                                        )
                                                      ) : (
                                                        " "
                                                      )
                                                    ) : (
                                                      <span
                                                        style={{
                                                          display: "block",
                                                          paddingLeft: "2em",
                                                        }}
                                                      >
                                                        {item.disorder.length
                                                          ? removeLastCommaAndWord(
                                                              item.disorder
                                                            )
                                                          : " "}
                                                      </span>
                                                    )}
                                                  </React.Fragment>
                                                )
                                              )
                                          : " "}
                                      </li>
                                      <FormatArray
                                        subIndex={"code"}
                                        keyName="ICD-10 CM code"
                                        value={(() => {
                                          const gadData =
                                            diagnosisList.find(
                                              (item) => item.gad
                                            )?.gad?.diagnosis || [];
                                          if (gadData.length > 0) {
                                            return gadData.map(
                                              (
                                                item: { code: string | any[] },
                                                index:
                                                  | React.Key
                                                  | null
                                                  | undefined
                                              ) => (
                                                <React.Fragment key={index}>
                                                  {index === 0 ? (
                                                    item.code?.length ? (
                                                      `${item.code}`
                                                    ) : (
                                                      " "
                                                    )
                                                  ) : (
                                                    <span
                                                      style={{
                                                        display: "block",
                                                        paddingLeft: "2em",
                                                      }}
                                                    >
                                                      {item.code?.length
                                                        ? `${item.code}`
                                                        : " "}
                                                    </span>
                                                  )}
                                                </React.Fragment>
                                              )
                                            );
                                          }
                                          return " ";
                                        })()}
                                      />

                                      <FormatArray
                                        subIndex={"severity"}
                                        keyName="Severity"
                                        value={(() => {
                                          const gadData =
                                            diagnosisList.find(
                                              (item) => item.gad
                                            )?.gad?.diagnosis || [];
                                          if (gadData.length > 0) {
                                            return gadData.map(
                                              (
                                                item: {
                                                  severity: string | any[];
                                                },
                                                index:
                                                  | React.Key
                                                  | null
                                                  | undefined
                                              ) => (
                                                <React.Fragment key={index}>
                                                  {index === 0 ? (
                                                    item.severity?.length ? (
                                                      `${item.severity}`
                                                    ) : (
                                                      " "
                                                    )
                                                  ) : (
                                                    <span
                                                      style={{
                                                        display: "block",
                                                        paddingLeft: "2em",
                                                      }}
                                                    >
                                                      {item.severity?.length
                                                        ? `${item.severity}`
                                                        : " "}
                                                    </span>
                                                  )}
                                                </React.Fragment>
                                              )
                                            );
                                          }
                                          return " ";
                                        })()}
                                      />

                                      <FormatArray
                                        subIndex={"diagnostic_frameworks"}
                                        keyName="Diagnostic Frameworks"
                                        value={
                                          "ICD-10 & DSM-5 Anxiety disorders"
                                        }
                                      />
                                    </ul>
                                  </>
                                )}
                              
                                 {((sessionSummaryListData.length &&
                                availableDignosisTypeList.length &&
                                availableDignosisTypeList.includes("sud")) ||
                                String(
                                  sessionSummaryListData.find(
                                    ([key]) => key === "SUD"
                                  )?.[1] ?? "No"
                                ) === "Yes")
                                && (
                                  <>
                                    <h3 style={{ marginLeft: "20px" }}>
                                      Substance Use Disorder
                                    </h3>
                                    <ul>
                                      <li key="Principal Diagnosis">
                                        <strong>Principal Diagnosis:</strong>{" "}
                                        {diagnosisList.find((item) => item.sud)
                                          ?.sud?.diagnosis
                                          ? diagnosisList
                                              .find((item) => item.sud)
                                              ?.sud?.diagnosis.map(
                                                (
                                                  item: {
                                                    disorder: string | any[];
                                                    severity: string | any[];
                                                  },
                                                  index:
                                                    | React.Key
                                                    | null
                                                    | undefined
                                                ) => (
                                                  <React.Fragment key={index}>
                                                    {index === 0 ? (
                                                      item.disorder.length ? (
                                                        removeLastCommaAndWord(
                                                          item.disorder
                                                        )
                                                      ) : (
                                                        " "
                                                      )
                                                    ) : (
                                                      <span
                                                        style={{
                                                          display: "block",
                                                          paddingLeft: "2em",
                                                        }}
                                                      >
                                                        {item.disorder.length
                                                          ? removeLastCommaAndWord(
                                                              item.disorder
                                                            )
                                                          : " "}
                                                      </span>
                                                    )}
                                                  </React.Fragment>
                                                )
                                              )
                                          : " "}
                                      </li>
                                      <FormatArray
                                        subIndex={"code"}
                                        keyName="ICD-10 CM code"
                                        value={(() => {
                                          const sudData =
                                            diagnosisList.find(
                                              (item) => item.sud
                                            )?.sud?.diagnosis || [];
                                          if (sudData.length > 0) {
                                            return sudData.map(
                                              (
                                                item: { code: string | any[] },
                                                index:
                                                  | React.Key
                                                  | null
                                                  | undefined
                                              ) => (
                                                <React.Fragment key={index}>
                                                  {index === 0 ? (
                                                    item.code?.length ? (
                                                      `${item.code}`
                                                    ) : (
                                                      " "
                                                    )
                                                  ) : (
                                                    <span
                                                      style={{
                                                        display: "block",
                                                        paddingLeft: "2em",
                                                      }}
                                                    >
                                                      {item.code?.length
                                                        ? `${item.code}`
                                                        : " "}
                                                    </span>
                                                  )}
                                                </React.Fragment>
                                              )
                                            );
                                          }
                                          return " ";
                                        })()}
                                      />
                                      <FormatArray
                                        subIndex={"substance"}
                                        keyName="Main Substance"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["substance"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"secondary_diagnosis"}
                                        keyName="Other Substances"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["secondary_substances"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"severity"}
                                        keyName="Principal Diagnosis Severity"
                                        value={(() => {
                                          const sudData =
                                            diagnosisList.find(
                                              (item) => item.sud
                                            )?.sud?.diagnosis || [];
                                          if (sudData.length > 0) {
                                            return sudData.map(
                                              (
                                                item: {
                                                  severity: string | any[];
                                                },
                                                index:
                                                  | React.Key
                                                  | null
                                                  | undefined
                                              ) => (
                                                <React.Fragment key={index}>
                                                  {index === 0 ? (
                                                    item.severity?.length ? (
                                                      `${item.severity}`
                                                    ) : (
                                                      " "
                                                    )
                                                  ) : (
                                                    <span
                                                      style={{
                                                        display: "block",
                                                        paddingLeft: "2em",
                                                      }}
                                                    >
                                                      {item.severity?.length
                                                        ? `${item.severity}`
                                                        : " "}
                                                    </span>
                                                  )}
                                                </React.Fragment>
                                              )
                                            );
                                          }
                                          return " ";
                                        })()}
                                      />
                                      <FormatArray
                                        subIndex={"substance"}
                                        keyName="Diagnosis Frameworks"
                                        value="ICD-10 & DSM-5 substance use disorder."
                                      />
                                    </ul>
                                  </>
                                )}

                              <span style={sessionReportTitle}>
                                Diagnosis References
                              </span>
                              {((sessionSummaryListData.length &&
                                availableDignosisTypeList.length &&
                                availableDignosisTypeList.includes("mdd")) ||
                                String(
                                  sessionSummaryListData.find(
                                    ([key]) => key === "MDD"
                                  )?.[1] ?? "No"
                                ) === "Yes") && (
                                  <>
                                    <h3 style={{ marginLeft: "20px" }}>
                                      Major Depressive Disorder
                                    </h3>
                                    <ul>
                                      <li style={{ fontWeight: "bold" }}>
                                        Symptoms:
                                      </li>
                                      <SubFormatArray
                                        subIndex={"depressed_mood"}
                                        keyName="Depressed Mood"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["1_mdd_mood"] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "loss_of_interest_or_pleasure"
                                        }
                                        keyName="Loss of Interest or Pleasure"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["2_mdd_interest"] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "significant_weight_change_or_appetite_alteration"
                                        }
                                        keyName="Significant Weight Change or Appetite Alteration"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["3_mdd_weight"] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={"sleep_disturbances"}
                                        keyName="Sleep Disturbances"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["4_mdd_insomnia"] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "psychomotor_agitation_or_retardation"
                                        }
                                        keyName="Psychomotor Agitation or Retardation"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["5_mdd_retardation"] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={"fatigue_or_loss_of_energy"}
                                        keyName="Fatigue or Loss of Energy"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["6_mdd_fatigue"] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "feelings_of_worthlessness_or_excessive_guilt"
                                        }
                                        keyName="Feelings of Worthlessness or Excessive Guilt"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["7_mdd_guilt"] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "Diminished_ability_to_think _or_concentrate"
                                        }
                                        keyName="Diminished Ability to Think or Concentrate"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["8_mdd_concentration"] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "Recurrent Thoughts of Death or Suicide"
                                        }
                                        keyName="Recurrent Thoughts of Death or Suicide"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["9_mdd_suicide"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"persistency_of_symptoms"}
                                        keyName="Persistency of Symptoms"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["10_mdd_symptom_count"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"functional_impairment"}
                                        keyName="Functional Impairment"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["11_mdd_impairment"] || []
                                        }
                                      />

                                      <li style={{ fontWeight: "bold" }}>
                                        Exclusions:
                                      </li>
                                      <SubFormatArray
                                        subIndex={
                                          "substance_use_medication_or_medicalcondition"
                                        }
                                        keyName="Substance Use, Medication, or Medical Condition"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.[
                                            "12_mdd_exclusion_medical"
                                          ] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "schizophrenia_spectrum_or_other_psychotic_disorders"
                                        }
                                        keyName="Schizophrenia Spectrum or Other Psychotic Disorders"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["13_mdd_exclusion_schizo"] ||
                                          []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "history_of_hypomanic_or_manic_Episodes"
                                        }
                                        keyName="History of Hypomanic or Manic Episodes"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_mdd"
                                          )?.[1]?.["14_mdd_hypomanic"] || []
                                        }
                                      />
                                    </ul>
                                  </>
                                )}

                              {((sessionSummaryListData.length &&
                                availableDignosisTypeList.length &&
                                availableDignosisTypeList.includes("gad")) ||
                                String(
                                  sessionSummaryListData.find(
                                    ([key]) => key === "GAD"
                                  )?.[1] ?? "No"
                                ) === "Yes") && (
                                  <>
                                    <h3 style={{ marginLeft: "20px" }}>
                                      Generalized Anxiety Disorder
                                    </h3>
                                    <ul>
                                      <li style={{ fontWeight: "bold" }}>
                                        Symptoms:
                                      </li>
                                      <SubFormatArray
                                        subIndex={"excessive_anxiety_orworry"}
                                        keyName="Excessive Anxiety or Worry"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_gad"
                                          )?.[1]?.["1_gad_symptom"] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "difficulty_in_controlling_theworry"
                                        }
                                        keyName="Difficulty in Controlling the Worry"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_gad"
                                          )?.[1]?.["3_gad_control_of_worry"] ||
                                          []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "Physical_or_cognitive_symptoms"
                                        }
                                        keyName="Physical or Cognitive Symptoms"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_gad"
                                          )?.[1]?.["2_gad_physical_symptoms"] ||
                                          []
                                        }
                                      />

                                      <FormatArray
                                        subIndex={"functional_impairment"}
                                        keyName="Functional Impairment"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_gad"
                                          )?.[1]?.["11_mdd_impairment"] || []
                                        }
                                      />

                                      <li style={{ fontWeight: "bold" }}>
                                        Exclusions:
                                      </li>
                                      <SubFormatArray
                                        subIndex={
                                          "substance_use_medication_or_medicalcondition"
                                        }
                                        keyName="Panic Disorder"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_gad"
                                          )?.[1]?.["5a_gad_panic_exclusion"] ||
                                          []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={"social_anxiety_disorder"}
                                        keyName="Social Anxiety Disorder"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_gad"
                                          )?.[1]?.[
                                            "5b_gad_social_anxiety_exclusion"
                                          ] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "obsessive_compulsive_disorder"
                                        }
                                        keyName="Obsessive-Compulsive Disorder"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_gad"
                                          )?.[1]?.["5c_gad_ocd_exclusion"] || []
                                        }
                                      />
                                      <SubFormatArray
                                        subIndex={
                                          "substance_use_medication_or_medicalcondition"
                                        }
                                        keyName="Substance Use, Medication, or Medical Condition"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_gad"
                                          )?.[1]?.[
                                            "5d_gad_medical_exclusion"
                                          ] || []
                                        }
                                      />
                                    </ul>
                                  </>
                                )}
                              {((sessionSummaryListData.length &&
                                availableDignosisTypeList.length &&
                                availableDignosisTypeList.includes("sud")) ||
                                String(
                                  sessionSummaryListData.find(
                                    ([key]) => key === "SUD"
                                  )?.[1] ?? "No"
                                ) === "Yes")
                                &&  (
                                  <>
                                    <h3 style={{ marginLeft: "20px" }}>
                                      Substance Use Disorder
                                    </h3>
                                    <ul>
                                      <FormatArray
                                        subIndex={"1_dsm_control_usage"}
                                        keyName="Impaired Control - Usage"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["1_dsm_control_usage"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"2_dsm_control_desire"}
                                        keyName="Impaired Control - Desire To Cut Down"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["2_dsm_control_desire"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"3_dsm_control_commitment"}
                                        keyName="Impaired Control - Time Commitment"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.[
                                            "3_dsm_control_commitment"
                                          ] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"4_dsm_control_arduous"}
                                        keyName="Impaired Control - Craving"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["4_dsm_control_arduous"] ||
                                          []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"5_dsm_social_failure"}
                                        keyName="Social Impairment - Failure"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["5_dsm_social_failure"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"6_dsm_social_persistence"}
                                        keyName="Social Impairment - Persistence"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.[
                                            "6_dsm_social_persistence"
                                          ] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"7_dsm_social_decline"}
                                        keyName="Social Impairment - Decline"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["7_dsm_social_decline"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"8_dsm_risk_hazard"}
                                        keyName="Risky Use - Hazard"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["8_dsm_risk_hazard"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"9_dsm_risk_tenacity"}
                                        keyName="Risky Use - Tenacity"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["9_dsm_risk_tenacity"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"10_dsm_tolerance"}
                                        keyName="Tolerance"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["10_dsm_tolerance"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={
                                          "10_a_dsm_tolerance_dosage_increase"
                                        }
                                        keyName="Tolerance - Dosage Increase"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.[
                                            "10_a_dsm_tolerance_dosage_increase"
                                          ] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"11_dsm_withdrawal"}
                                        keyName="Withdrawal"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.["11_dsm_withdrawal"] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={"11_a_dsm_withdrawal_relief"}
                                        keyName="Withdrawal - Relief"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.[
                                            "11_a_dsm_withdrawal_relief"
                                          ] || []
                                        }
                                      />
                                      <FormatArray
                                        subIndex={
                                          "11_b_dsm_withdrawal_symptoms"
                                        }
                                        keyName="Withdrawal - Symptoms"
                                        value={
                                          sessionSummaryListData.find(
                                            ([key]) => key === "summary_sud"
                                          )?.[1]?.[
                                            "11_b_dsm_withdrawal_symptoms"
                                          ] || []
                                        }
                                      />
                                    </ul>
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}

                    {!diagnosisLoading &&
                      !isAllDignosisEmpty &&
                      (!Boolean(sessionSummaryListData.length) ||
                        !Boolean(availableDignosisTypeList.length)) && (
                        <Typography
                          style={{ textAlign: "center", fontSize: "small" }}
                        >
                          Your reports are being generated. They will
                          automatically appear on screen once ready.
                        </Typography>
                      )}
                    {isAllDignosisEmpty && String(
                                  sessionSummaryListData.find(
                                    ([key]) => key === "SUD"
                                  )?.[1] ?? "No"
                                ) === "No" && String(
                                  sessionSummaryListData.find(
                                    ([key]) => key === "MDD"
                                  )?.[1] ?? "No"
                                ) === "No" && String(
                                  sessionSummaryListData.find(
                                    ([key]) => key === "GAD"
                                  )?.[1] ?? "No"
                                ) === "No" &&(
                      <>
                        <span style={sessionReportTitle}>
                          Diagnosis Summary
                        </span>
                        <ul>
                          <li key="Principal Diagnosis">
                            <strong>Principal Diagnosis:</strong>
                          </li>
                          <FormatArray
                            subIndex={"code"}
                            keyName="ICD-10 CM code"
                            value=" "
                          />
                        </ul>
                        <span style={sessionReportTitle}>
                          Diagnosis Details
                        </span>

                        <h3 style={{ marginLeft: "20px" }}>
                          Substance Use Disorder
                        </h3>
                        <ul>
                          <li key="Principal Diagnosis">
                            <strong>Principal Diagnosis:</strong>
                          </li>
                          <FormatArray
                            subIndex={"code"}
                            keyName="ICD-10 CM code"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"substance"}
                            keyName="Main Substance"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"secondary_diagnosis"}
                            keyName="Other Substances"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"severity"}
                            keyName="Principal Diagnosis Severity"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"substance"}
                            keyName="Diagnosis Frameworks"
                            value=" "
                          />
                        </ul>
                        <span style={sessionReportTitle}>
                          Diagnosis References
                        </span>

                        <h3 style={{ marginLeft: "20px" }}>
                          Substance Use Disorder
                        </h3>
                        <ul>
                          <FormatArray
                            subIndex={"1_dsm_control_usage"}
                            keyName="Impaired Control - Usage"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"2_dsm_control_desire"}
                            keyName="Impaired Control - Desire To Cut Down"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"3_dsm_control_commitment"}
                            keyName="Impaired Control - Time Commitment"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"4_dsm_control_arduous"}
                            keyName="Impaired Control - Craving"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"5_dsm_social_failure"}
                            keyName="Social Impairment - Failure"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"6_dsm_social_persistence"}
                            keyName="Social Impairment - Persistence"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"7_dsm_social_decline"}
                            keyName="Social Impairment - Decline"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"8_dsm_risk_hazard"}
                            keyName="Risky Use - Hazard"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"9_dsm_risk_tenacity"}
                            keyName="Risky Use - Tenacity"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"10_dsm_tolerance"}
                            keyName="Tolerance"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"10_a_dsm_tolerance_dosage_increase"}
                            keyName="Tolerance - Dosage Increase"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"11_dsm_withdrawal"}
                            keyName="Withdrawal"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"11_a_dsm_withdrawal_relief"}
                            keyName="Withdrawal - Relief"
                            value=" "
                          />
                          <FormatArray
                            subIndex={"11_b_dsm_withdrawal_symptoms"}
                            keyName="Withdrawal - Symptoms"
                            value=" "
                          />
                        </ul>
                      </>
                    )}
                    {diagnosisLoading && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 3 }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Typography>
                </Box>

                {!diagnosisLoading &&
                  !diagnosisIsExpanded &&
                  diagnosisList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("diagosis")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {diagnosisIsExpanded ? "View Less" : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() => downloadTxtFile("aida_dignosis")}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {diagnosisIsExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}

        {/* Session Transcript */}
        {!sessionSummaryIsExpanded &&
          !diagnosisIsExpanded &&
          !treatmentIsExpanded && (
            <Grid item xs={12} md={sessionTranscriptisExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: sessionTranscriptisExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00", // Keeps the background color the same on hover
                        },
                      }}
                    >
                      <Chat sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      Session Transcript
                    </Typography>

                    <div>
                      <CachedIcon
                        onClick={fetchSessionTranscript}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <Box height={10}></Box>

                  {!sessionTranscriptLoading &&
                    !sessionTranscriptList.length && (
                      <Typography
                        style={{ textAlign: "center", fontSize: "small" }}
                      >
                        Your reports are being generated. They will
                        automatically appear on screen once ready.
                      </Typography>
                    )}

                  {!sessionTranscriptLoading && (
                    <Typography
                      variant="body2"
                      component="div"
                      id="sessionTranscriptDiv"
                      sx={{
                        flex: 1, // Makes it fill the available space
                        minHeight: "150px",
                        maxHeight: sessionTranscriptisExpanded
                          ? "300px"
                          : "50px", // Corrected to "50px"
                        overflowY: sessionTranscriptisExpanded
                          ? "auto"
                          : "hidden",
                        transition: "max-height 0.3s ease", // Smooth transition when expanding/collapsing
                        overflowX: "hidden",
                      }}
                    >
                      {sessionTranscriptList.map((item, i) => (
                        <div key={i}>
                          <strong style={{ justifyContent: "flex-end" }}>
                            {item.speaker_label}:
                          </strong>{" "}
                          {item.transcript}
                          <br />
                        </div>
                      ))}
                    </Typography>
                  )}

                  {sessionTranscriptLoading && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ mt: 3 }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>

                {!sessionTranscriptLoading &&
                  !sessionTranscriptisExpanded &&
                  sessionTranscriptList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("session_transcript")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {sessionTranscriptisExpanded
                          ? "View Less"
                          : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() => downloadTxtFile("session_transcript")}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {sessionTranscriptisExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}

        {/* AIDA Treatment Plan Recommendation */}
        {!sessionTranscriptisExpanded &&
          !sessionSummaryIsExpanded &&
          !diagnosisIsExpanded && (
            <Grid item xs={12} md={treatmentIsExpanded ? 12 : 6}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: treatmentIsExpanded ? "300px" : "268px",
                  transition: "all 0.3s ease",
                  border: "1px solid #cfcfcf",
                }}
              >
                <Box sx={{ flex: "1 0 auto", p: 2 }}>
                  <div
                    style={{
                      padding: "0px",
                      fontSize: "small",
                      borderRadius: "10px", // Adjust the border radius as needed
                      display: "flex",

                      gap: "10px", // Adjust the gap between icon and text as needed
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "50%", // Makes the button rounded
                        // border: "1px solid black",
                        backgroundColor: "#ffcc00",
                        fontSize: "small",
                        cursor: "default",
                        "&:hover": {
                          backgroundColor: "#ffcc00", // Keeps the background color the same on hover
                        },
                      }}
                    >
                      <Chat sx={{ fontSize: "small", color: "black" }} />
                    </IconButton>

                    <Typography sx={{ fontWeight: "bold" }}>
                      AIDA Treatment Plan Recommendation
                    </Typography>

                    <div>
                      <CachedIcon
                        onClick={fetchSessionAIDATreatmentPlan}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <Box height={10}></Box>
                  <Typography
                    variant="body2"
                    component="div"
                    id="sessionTreatmentPlanDiv"
                    sx={{
                      flex: 1, // Makes it fill the available space
                      minHeight: "150px", // Set minimum height for the transcript
                      maxHeight: treatmentIsExpanded ? "300px" : "50px", // Expand/Collapse max height
                      overflowY: treatmentIsExpanded ? "auto" : "hidden",
                      transition: "max-height 0.3s ease", // Smooth transition when expanding/collapsing
                      overflowX: "hidden",
                    }}
                  >
                    {!diagnosisLoading && diagnosisList.length > 0 && (
                      <>
                        <span style={sessionReportTitle}>
                          Treatment plan summary
                        </span>
                        <ul>
                          <FormatArray
                            subIndex={"pharmacotherapy"}
                            keyName="Medication"
                            value={treatmentDetailInfo.pharmacotherapy}
                          />
                          <FormatArray
                            subIndex={"psychotherapy"}
                            keyName="Therapy"
                            value={treatmentDetailInfo.psychotherapy}
                          />
                        </ul>
                        <span style={sessionReportTitle}>Pharmacological</span>
                        <Box height={10}></Box>
                        <TreatmentPlanPharmacologicalTable
                          data={pharmacologicalMedicationList}
                        />
                        <Box height={10}></Box>
                        <span style={sessionReportTitle}>
                          Non-Pharmacological
                        </span>
                        <Box height={10}></Box>
                        <TreatmentPlanNonPharmacologicalTable
                          data={nonPharmacologicalMedicationList}
                        />
                      </>
                    )}
                    {!treatmentLoading && !treatmentPlanList.length && (
                      <Typography
                        style={{ textAlign: "center", fontSize: "small" }}
                      >
                        Your reports are being generated. They will
                        automatically appear on screen once ready.
                      </Typography>
                    )}
                    {treatmentLoading && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 3 }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Typography>
                </Box>

                {!treatmentLoading &&
                  !treatmentIsExpanded &&
                  !sessionTranscriptisExpanded &&
                  treatmentPlanList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        p: 1,
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
                        onClick={() => handleViewMore("treatment_plan")}
                        sx={{ textTransform: "none", color: "black" }}
                      >
                        {treatmentIsExpanded ? "View Less" : "View More"}
                      </Button>
                      <Button
                        size="small"
                        endIcon={<DownloadIcon />}
                        sx={{ textTransform: "none", color: "black" }}
                        onClick={() =>
                          downloadTxtFile("session_treatment_plan")
                        }
                      >
                        Download
                      </Button>
                    </Box>
                  )}
              </Paper>

              {/* Feedback */}
              {treatmentIsExpanded && (
                <SessionFeedback
                  sessionid={sessionid}
                  reportid={currentExpandedReport}
                  feedbackExpanded={feedbackExpanded}
                />
              )}
            </Grid>
          )}
      </Grid>
    </AppDrawer>
  );
};

export default SessionReport;
